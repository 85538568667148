const revealParents = document.querySelectorAll(
    '[data-behaviour="reveal-parent"]'
);
const revealOnHover = document.querySelector('[data-ref="hover-reveal"]');

if (revealParents.length > 0) {
    revealParents.forEach((parent) => {
        const revealContentBtn = parent.querySelector(
            '[data-behaviour="reveal-content-btn"]'
        );
        let section = parent.nextElementSibling;

        if (revealContentBtn != null) {
            revealContentBtn.addEventListener('click', function (e) {
                if (section != null) {
                    let certs = document.querySelector(
                        '[data-ref="certifications"]'
                    );
                    let isCollapsed =
                        section.getAttribute('data-collapsed') === 'true';

                    if (isCollapsed) {
                        expandSection(section);
                        revealContentBtn.classList.add('active');

                        if (certs != null) {
                            certs.classList.add('active');
                        }
                        section.setAttribute('data-collapsed', 'false');
                    } else {
                        if (certs != null) {
                            certs.classList.remove('active');
                        }
                        revealContentBtn.classList.remove('active');
                        collapseSection(section);
                    }
                }
            });
        }
    });
}

function collapseSection(element) {
    let sectionHeight = element.scrollHeight;

    let elementTransition = element.style.transition;
    element.style.transition = 'all 0.3s ease-in-out';

    requestAnimationFrame(function () {
        element.style.maxHeight = sectionHeight + 'px';
        element.style.transition = elementTransition;

        requestAnimationFrame(function () {
            element.style.maxHeight = 0 + 'px';
        });
    });

    element.setAttribute('data-collapsed', 'true');
}

function expandSection(element) {
    let sectionHeight = element.scrollHeight;
    element.style.maxHeight = sectionHeight + 'px';
    
    setTimeout(() => {
        element.style.maxHeight = 'none';
    }, 300);
    
    element.addEventListener('transitionend', function (e) {
        element.removeEventListener('transitionend', arguments.callee);
    });

    element.setAttribute('data-collapsed', 'false');
}
