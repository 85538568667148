import MiniSearch from 'minisearch'

const faqs = document.querySelectorAll(
    '[data-behaviour="faq-search"]'
);

if( faqs.length > 0 ) {

    faqs.forEach((faq) => {
        
        let documents = JSON.parse(faq.getAttribute('data-documents') ?? '{}');

        const input = faq.querySelector('[data-ref="search-input"]');
        const submit = faq.querySelector('[data-ref="search-submit"]');
        const parent = faq.closest('.faqs__list');
        const faqItems = parent.querySelectorAll('[data-target="faq"]');
        const sectionTitles = parent.querySelectorAll('[data-target="section-title"]');
        const groupTitles = parent.querySelectorAll('[data-target="group-title"]');
        const resultCount = faq.querySelector('[data-ref="result-count"]');


        let miniSearch = new MiniSearch({
            fields: ['title', 'content'], // fields to index for full-text search
            storeFields: ['title'] // fields to return with search results
        });
        
        miniSearch.addAll(documents)

        submit.addEventListener('click', function () {
            
            const term = input.value;

            if(term) {

                let results = miniSearch.search(term, { fields: ['title', 'content'] })
                let resultsCount = results.length;

                resultCount.classList.remove('u-hidden');

                if( resultsCount === 0 ) {
                    resultCount.innerHTML = `No results to show`;
                } else if( resultsCount === 1 ) {
                    resultCount.innerHTML = `Showing ${resultsCount} result`;
                } else {
                    resultCount.innerHTML = `Showing ${resultsCount} results`;
                }

                if( resultsCount ) {

                    faqItems.forEach((item) => {
                        item.classList.add('u-hidden');
                    });
                    
                    sectionTitles.forEach((item) => {
                        item.classList.add('u-hidden');
                    });
    
                    groupTitles.forEach((item) => {
                        item.classList.add('u-hidden');
                    });

                    results.forEach((result) => {

                        const id = result.id;
                        const activeFaqs = parent.querySelectorAll('[data-index="' + id + '"]');

                        activeFaqs.forEach((item) => {
                            item.classList.remove('u-hidden');
                            const parent = item.closest('.faqs__section');

                            if( parent ) {
                                const sectionTitle = parent.querySelector('[data-target="section-title"]');
                                if( sectionTitle ) {
                                    sectionTitle.classList.remove('u-hidden');
                                }
                                const groupTitle = parent.querySelector('[data-target="group-title"]');
                                if (groupTitle) {
                                    groupTitle.classList.remove('u-hidden');
                                }
                            }
                            
                        });
                        
                    });
                
                }

            } else {

                resultCount.classList.add('u-hidden');

                sectionTitles.forEach((item) => {
                    item.classList.remove('u-hidden');
                });

                groupTitles.forEach((item) => {
                    item.classList.remove('u-hidden');
                });

                faqItems.forEach((item) => {
                    item.classList.remove('u-hidden');
                });
            }

        });

    });
    
}