import React, { useState, useEffect, useContext } from "react";

const ActivePriceFilters = ( { min, max, label} ) => {

    return (
        <>
            { min || max ? (
                <div>
                    { label }
                    { min ? (
                        <> from {min}</>
                    ) : null }
                    { max ? (
                        <> to {max}</>
                    ) : null }
                </div>
            ) : null}
        </>
    );
};

export default ActivePriceFilters;