import React, { useState } from 'react';
import ChevronRightIcon from '../Icons/ChevronRightIcon';

const SearchCTACard = ({ cta }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div
            className={
                'search__cta-card card' +
                (isActive ? ' search__cta-card--rollover' : '')
            }
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
        >
            <a href={cta.on_hover.cta_link}>
                {isActive ? (
                    <div className="search__cta-card-inner">
                        <img src={cta.on_hover.icon} alt={cta.on_hover.title} />
                        <p className="search__cta-card-text-2">
                            {cta.on_hover.title}
                        </p>
                        <p className="search__cta-card-text-3">
                            {cta.on_hover.subtitle}
                        </p>
                        <div className="search__cta-card-bar">
                            {cta.on_hover.cta_text} <ChevronRightIcon />
                        </div>
                    </div>
                ) : (
                    <div className="search__cta-card-inner">
                        <img src={cta.default.icon} alt={cta.default.title} />
                        <p className="search__cta-card-text-1">
                            {cta.default.title}
                        </p>
                        <span className="search__cta-card-rollover">
                            {cta.default.cta_text}
                        </span>
                    </div>
                )}
            </a>
        </div>
    );
};

export default SearchCTACard;
