import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17812 18.7349C9.17812 18.7349 9.16146 18.7349 9.15313 18.7349C4.32669 18.3017 0.688809 14.3306 0.688809 9.49861C0.688809 4.66939 4.32946 0.701049 9.15313 0.267836C9.31142 0.253951 9.44193 0.367808 9.45582 0.520544C9.4697 0.673279 9.35585 0.809352 9.20311 0.823237C4.66826 1.22868 1.24699 4.9582 1.24699 9.49861C1.24699 14.0418 4.66826 17.7741 9.20589 18.1823C9.35862 18.1962 9.47248 18.3323 9.4586 18.485C9.44471 18.6294 9.3253 18.7377 9.1809 18.7377L9.17812 18.7349Z"
        fill="url(#paint0_linear_2817_39250)"
      />
      <path
        d="M9.17812 18.8738H9.14202C4.24338 18.4322 0.549959 14.4028 0.552736 9.4986C0.552736 4.59719 4.24615 0.567753 9.14202 0.128986C9.2531 0.117878 9.3614 0.151202 9.44749 0.223404C9.53358 0.295606 9.58634 0.395578 9.59467 0.506659C9.61411 0.734373 9.44471 0.937095 9.217 0.959311C4.75435 1.3592 1.38584 5.0304 1.38584 9.4986C1.38584 13.9696 4.75157 17.6436 9.217 18.0435C9.44471 18.0629 9.61411 18.2656 9.59467 18.4961C9.57523 18.7127 9.3975 18.8766 9.1809 18.8766L9.17812 18.8738ZM9.17812 0.403909C9.17812 0.403909 9.16979 0.403909 9.16423 0.403909C4.41555 0.831568 0.830437 4.74159 0.830437 9.4986C0.830437 14.2584 4.41277 18.1712 9.16701 18.5961L9.1809 18.7349V18.5961C9.2531 18.5961 9.31141 18.5405 9.31975 18.4683C9.3253 18.3934 9.26976 18.3239 9.19478 18.3184C4.58495 17.9046 1.10814 14.1112 1.11091 9.49583C1.11091 4.88322 4.58773 1.09261 9.19478 0.678833C9.26976 0.673279 9.32808 0.603854 9.31975 0.528875C9.31697 0.489997 9.29753 0.456673 9.26976 0.434456C9.2531 0.420571 9.22255 0.403909 9.18367 0.403909H9.17812Z"
        fill="url(#paint1_linear_2817_39250)"
      />
      <path
        d="M10.8196 18.7377C10.678 18.7377 10.5558 18.6294 10.5419 18.485C10.528 18.3323 10.6419 18.1962 10.7946 18.1823C15.3294 17.7741 18.7507 14.0446 18.7535 9.50416C18.7535 4.96097 15.3322 1.22868 10.7946 0.820457C10.6419 0.806572 10.528 0.670499 10.5419 0.517763C10.5558 0.365028 10.6863 0.253948 10.8446 0.265056C15.671 0.698269 19.3089 4.66939 19.3089 9.50138C19.3089 14.3306 15.6682 18.2989 10.8446 18.7321C10.8363 18.7321 10.8279 18.7321 10.8196 18.7321V18.7377Z"
        fill="url(#paint2_linear_2817_39250)"
      />
      <path
        d="M10.8196 18.8766C10.603 18.8766 10.4253 18.7127 10.4058 18.4961C10.3864 18.2684 10.5558 18.0657 10.7835 18.0434C15.2461 17.6436 18.6146 13.9724 18.6146 9.50416C18.6146 5.03318 15.2489 1.3592 10.7835 0.959307C10.5558 0.939868 10.3864 0.737147 10.4058 0.506655C10.4253 0.276164 10.6196 0.109543 10.8557 0.128982C15.7543 0.56775 19.4477 4.59996 19.4477 9.50416C19.4477 14.4056 15.7543 18.435 10.8585 18.8738H10.8196V18.8766ZM10.8196 0.40946C10.7474 0.40946 10.6891 0.462223 10.6807 0.537202C10.6752 0.612182 10.7307 0.681607 10.8057 0.687161C15.4155 1.10093 18.8923 4.89432 18.8896 9.50971C18.8896 14.1223 15.4128 17.9129 10.8057 18.3267C10.7307 18.3323 10.6724 18.4017 10.6807 18.4767C10.6863 18.5461 10.7474 18.6044 10.8196 18.6044V18.7433L10.8335 18.6044C15.5849 18.1767 19.1673 14.2695 19.17 9.51249C19.17 4.7527 15.5877 0.839896 10.8335 0.415014C10.8307 0.415014 10.8251 0.415014 10.8224 0.415014L10.8196 0.40946Z"
        fill="url(#paint3_linear_2817_39250)"
      />
      <path
        d="M13.4569 11.8924C13.4069 11.8924 13.357 11.8785 13.3125 11.8507L9.72186 9.6569V2.25618C9.72186 2.10345 9.84682 1.97848 9.99956 1.97848C10.1523 1.97848 10.2773 2.10345 10.2773 2.25618V9.34588L13.6013 11.3786C13.7319 11.4592 13.7735 11.6286 13.693 11.7619C13.6402 11.848 13.5486 11.8952 13.4569 11.8952V11.8924Z"
        fill="url(#paint4_linear_2817_39250)"
      />
      <path
        d="M13.4569 12.0312C13.382 12.0312 13.307 12.009 13.2403 11.9701L9.58301 9.73466V2.25618C9.58301 2.02569 9.76907 1.83963 9.99956 1.83963C10.23 1.83963 10.4161 2.02569 10.4161 2.25618V9.26812L13.6763 11.2592C13.7707 11.3176 13.8374 11.4092 13.8652 11.5175C13.8901 11.6258 13.8735 11.7369 13.8152 11.8313C13.7402 11.9563 13.6069 12.0312 13.4597 12.0312H13.4569ZM9.86071 9.57915L13.3847 11.7341C13.4486 11.773 13.5375 11.7508 13.5763 11.6869C13.5958 11.6563 13.6013 11.6175 13.593 11.5814C13.5847 11.5453 13.5625 11.5147 13.5291 11.4953L10.1384 9.42364V2.25618C10.1384 2.17842 10.0773 2.11733 9.99956 2.11733C9.9218 2.11733 9.86071 2.17842 9.86071 2.25618V9.58192V9.57915Z"
        fill="url(#paint5_linear_2817_39250)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2817_39250"
          x1="0.552734"
          y1="0.126892"
          x2="19.3018"
          y2="19.0213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC683B" />
          <stop offset="1" stopColor="#E14AAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ClockIcon;
