import { debounce } from '../modules/utilities';

const header = document.querySelector('[data-ref="header"]');

if (header) {
    /**
     * Menu Vars
     */
    const primary_menu = header.querySelector('[data-ref="header-menu"]');
    const primary_menu_parents = primary_menu.querySelectorAll(
        '[data-behaviour="open-mega-nav"]'
    );
    const hamburger = header.querySelector('[data-behaviour="toggle-nav"]');
    /**
     * Search Vars
     */
    const search_toggle = header.querySelector(
        '[data-behaviour="drop-down-search"]'
    );
    const search_parent = header.querySelector(
        '[data-ref="site-header-search"]'
    );
    const search_form = header.querySelector('[data-ref="site-header-form"]');
    let search_state = 'closed';
    /**
     * Class Vars
     */
    const primary_menu_mobile_active = 'site-header__menu-primary--open';
    const active_state_class = 'site-header__menu-primary-item--active';

    /**
     * Menu
     * Add click event to menu items
     */
    primary_menu_parents.forEach((menu_item) => {
        menu_item.addEventListener('click', (e) => {
            e.preventDefault();
            menu_item
                .closest('[data-ref="list-item"]')
                .classList.toggle(active_state_class);
        });
    });

        
    /**
     * Menu
     * Search toggle
     */
    if (search_toggle && search_parent && search_form) {
        document.addEventListener('click', (e) => {

            if( e.target.dataset.behaviour == "drop-down-search" ||
                e.target.dataset.behaviour == "site-header-searc" ||
                e.target.dataset.behaviour == "site-header-form"
            ) {
                e.preventDefault();
            }
            
            /**
             * If click inside search do nothing
             */
            let inside_form = e.target.closest(
                '[data-ref="site-header-search"]'
            );
            if (inside_form) return;

            /**
             * if closed and is toggle button then close mega_menus and open search
             */
            if (
                search_state == 'closed' &&
                e.target.closest('[data-behaviour="drop-down-search"]')
            ) {
                close_menu();
                open_search();
                return;
            }
            /**
             * If click within header close mega_menu just incase and search
             */
            if (
                search_state == 'open' &&
                e.target.closest('[data-ref="site-header-search"]')
            ) {
                close_search();
                return;
            }

            close_search();
        });
    }
    /**
     * Hamburger
     * Add click event to menu
     */
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('hamburger--open');
        primary_menu.classList.toggle(primary_menu_mobile_active);
    });

    /**
     * Menu
     * Open search
     */
    const open_search = () => {
        search_parent.classList.add('site-header__search--open');
        header.classList.add('site-header--search-open');
        search_state = 'open';
    };
    /**
     * Menu
     * Close search
     */
    const close_search = () => {
        search_parent.classList.remove('site-header__search--open');
        header.classList.remove('site-header--search-open');
        search_state = 'closed';
    };

    /**
     * Menu
     * Close menu action
     *
     * @param {string} current_menu_item
     */
    const close_menu = (current_menu_item = '') => {
        /**
         * Remove active state of other elements
         */
        let active_menu_items = header.querySelectorAll(
            '.' + active_state_class
        );
        let current_parent = current_menu_item
            ? current_menu_item
                  .closest('[data-ref="list-item"]')
                  .classList.contains(active_state_class)
            : false;

        active_menu_items.forEach((active_menu) => {
            if (!current_parent) {
                active_menu.classList.remove(active_state_class);
            }
        });
    };
    /**
     * Menu
     * Close on hamburger and menu on resize
     */
    window.addEventListener(
        'resize',
        debounce(() => {
            /**
             * Close mobile
             */
            if (primary_menu.classList.contains(primary_menu_mobile_active)) {
                hamburger.classList.remove('hamburger--open');
                primary_menu.classList.remove(primary_menu_mobile_active);
            }
            /**
             * Close mega menu
             */
            close_menu();
        }, 250)
    );
}
