const hideBtn = document.querySelectorAll('[data-behaviour="hide"]');

if (hideBtn.length > 0) {
    hideBtn.forEach((element) => {
        const hideAnnouncement = document.querySelector('.announcement-bar');
        const hideAnnouncementCtas = document.querySelector('.announcement-ctas');
        element.addEventListener('click', () => {
            hideAnnouncement.classList.add('u-hidden');
            hideAnnouncementCtas.classList.add('u-hidden');
        });
    });
}
