import React, { useEffect, useContext, useState } from "react";
import { get_search_results, get_full_search_results } from "../Services/SearchAPI";
import { ResultContext } from "./Context/ResultContext";
import { SearchContext } from "./Context/SearchContext";
import SearchCTACard from "./SearchCTACard";
import SearchLoadingResults from "./SearchLoadingResults";
import SearchNoResults from "./SearchNoResults";
import SearchResult from "./SearchResult";

const SearchResults = ({ cta, fullSearch }) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [resultContext, setResultContext] = useContext(ResultContext);

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(async () => {
    setIsLoading(true);

    let data;

    if( fullSearch ) {
      data = await get_full_search_results(searchContext);
    } else {
      data = await get_search_results(searchContext);
    }

    setResults(data.results);
    setIsLoading(false);

    let resultData = {
      ...resultContext,
    };

    resultData.total_results = data.pagination.total_results;
    resultData.total_pages = data.pagination.total_pages;
    setResultContext(resultData);

  }, [searchContext]);

  if (isLoading) {
    return <SearchLoadingResults />;
  }
  
  if (results.length > 0) {
    return (
      <div className="search__results">
        {results.slice(0, 2).map((result) => {
          return <SearchResult key={result.id} result={result} />;
        })}
        <SearchCTACard cta={cta} />
        {results.slice(2).map((result) => {
          return <SearchResult key={result.id} result={result} />;
        })}
      </div>
    );
  }

  return <SearchNoResults />;
};

export default SearchResults;
