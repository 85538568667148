import React from "react";

const ArrowRightIcon = () => {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m7.497 4.755 1.821 1.819c.14.14.21.303.21.49 0 .186-.07.35-.21.49L7.484 9.392a.625.625 0 0 1-.481.185.686.686 0 0 1-.482-.201.664.664 0 0 1-.193-.49c0-.199.064-.362.193-.49l.63-.63H4.891a.65.65 0 0 1-.49-.2.695.695 0 0 1-.193-.499c0-.198.066-.364.2-.498a.676.676 0 0 1 .5-.202l2.239-.002-.648-.647a.626.626 0 0 1-.184-.481.685.685 0 0 1 .2-.481.664.664 0 0 1 .49-.193c.2 0 .362.064.49.192ZM7.003.065c-.968 0-1.878.185-2.73.554a7.064 7.064 0 0 0-2.22 1.497A7.064 7.064 0 0 0 .557 4.34a6.813 6.813 0 0 0-.55 2.73C.01 8.04.195 8.95.563 9.8A7.064 7.064 0 0 0 2.06 12.02a7.073 7.073 0 0 0 2.223 1.495 6.82 6.82 0 0 0 2.73.55 6.82 6.82 0 0 0 2.73-.554 7.076 7.076 0 0 0 2.222-1.498 7.065 7.065 0 0 0 1.494-2.224c.367-.851.55-1.762.55-2.73-.001-.968-.186-1.878-.554-2.73a7.064 7.064 0 0 0-1.498-2.22A7.063 7.063 0 0 0 9.734.614a6.813 6.813 0 0 0-2.73-.55Zm.001 1.4c1.552 0 2.874.543 3.966 1.633 1.091 1.09 1.637 2.411 1.639 3.963 0 1.552-.544 2.874-1.633 3.965-1.09 1.092-2.412 1.638-3.963 1.64-1.552 0-2.874-.544-3.965-1.634-1.092-1.09-1.638-2.411-1.64-3.963 0-1.551.544-2.873 1.634-3.965 1.09-1.091 2.41-1.638 3.962-1.639Z"
        fill="#315DA6"
      />
    </svg>
  );
};

export default ArrowRightIcon;
