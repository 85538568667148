export const SearchContextDefaultData = {
	query: "",
	types: [],
	post_type: [],
	category: null,
	price: {
		min: null,
		max: null
	},
	durations: [],
	credits: [],
	months: [],
	sort: "popularity",
	per_page: 12,
	current_page: 1,
	reset: null,
	formats: [],
	full_search: false,
	location: null,
}