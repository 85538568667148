const certificationBlocks = document.querySelectorAll(
    '[data-behaviour="certification-logos"]'
);

if (certificationBlocks.length > 0) {

    certificationBlocks.forEach( ( certificationBlock ) => {

        const blocks = certificationBlock.querySelectorAll(
            '[data-target="certification"]'
        );

        const toggleAll = certificationBlock.querySelector(
            '[data-target="certifications-reveal-all"]'
        );

        if ( blocks.length > 0 ) {

            blocks.forEach( ( block ) => {

                const toggle = block.querySelector(
                    '[data-target="certification-reveal"]'
                );
                const content = block.querySelector(
                    '[data-target="certification-content"]'
                );

                toggle.addEventListener('click', function (e) {
                    e.preventDefault();

                    if( block.getAttribute('data-active') === 'true' ) {
                        block.setAttribute('data-active', 'false');
                        collapseSection(content);
                    } else {
                        block.setAttribute('data-active', 'true');
                        expandSection(content);
                    }

                });

            });

        }


        if( toggleAll ) {

            toggleAll.addEventListener('click', function (e) {
                e.preventDefault();
                
                if( certificationBlock.getAttribute('data-active') === 'true' ) {

                    blocks.forEach( ( block ) => {
                    
                        const content = block.querySelector(
                            '[data-target="certification-content"]'
                        );
    
                        block.setAttribute('data-active', 'false');
                        collapseSection(content);
    
                    });

                    certificationBlock.setAttribute('data-active', 'false');

                } else {

                    blocks.forEach( ( block ) => {
                    
                        const content = block.querySelector(
                            '[data-target="certification-content"]'
                        );
    
                        block.setAttribute('data-active', 'true');
                        expandSection(content);
    
                    });

                    certificationBlock.setAttribute('data-active', 'true');

                }

            });

        }
        
    });
}

function collapseSection(element) {

    let sectionHeight = element.scrollHeight;
    let elementTransition = element.style.transition;
    element.style.transition = 'all 0.3s ease-in-out';

    requestAnimationFrame(function () {
        element.style.maxHeight = sectionHeight + 'px';
        element.style.transition = elementTransition;

        requestAnimationFrame(function () {
            element.style.maxHeight = 0 + 'px';
        });
    });

    element.setAttribute('data-collapsed', 'true');
}

function expandSection(element) {
    let sectionHeight = element.scrollHeight;
    element.style.maxHeight = sectionHeight + 'px';

    element.addEventListener('transitionend', function (e) {
        element.removeEventListener('transitionend', arguments.callee);
    });

    element.setAttribute('data-collapsed', 'false');
}
