const call_api = async (action, data = {}) => {
	const form_data = new FormData();
	form_data.append('security', wp_ajax.security);
	form_data.append('data', JSON.stringify(data));
	
	return fetch(`${wp_ajax.ajax_url}?action=${action}`, {
		method: 'POST',
		body: form_data
	})
	.then(response => response.json())
	.catch(error => {
		console.log('Error during fetch: ' + error);
	});
}

const getUserLocation = async (postalCode) => {
    return await call_api('get_user_location', {
		postCode: postalCode ? postalCode : false,
	})
	.then(response => {
		if (!response.success) {
			console.log(response);
		} else {
			localStorage.setItem('userLocation', response.data);
			return response;
		}
	});
}

const userLocation = localStorage.getItem('userLocation');

if(!userLocation) {
	getUserLocation();
}