const basket = document.querySelector(
    '[data-behaviour="product-basket"]'
);

if( basket ) {

    const tabs = basket.querySelectorAll('.product-basket__tab');
    const tabContents = basket.querySelectorAll('.product-basket__tab-content');

    tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {

            e.preventDefault();
            const id = e.target.dataset.action;
            const target = basket.querySelector(`[data-id="${id}"]`);

            tabs.forEach((tab) => {
                tab.classList.remove('product-basket__tab--active');
            });
            tab.classList.add('product-basket__tab--active');

            tabContents.forEach((content) => {
                
                content.classList.add('u-hidden');
                target.classList.remove('u-hidden');
            });

        });
    });
    
}
