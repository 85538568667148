import React, { useState, useContext } from "react";
import { ResultContext } from "./Context/ResultContext";
import { SearchContext } from "./Context/SearchContext";

const SearchPaginationDetails = ( ) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [resultContext, setResultContext] = useContext(ResultContext);

  const handleOnChange = (event) => {
    let data = {
      ...searchContext,
    };

    data.per_page = event.target.value;
    data.current_page = 1;
    setSearchContext(data);
  };

  const notEnoughResults = parseInt(resultContext.total_results) < 12;

  return (
    <div className="search__pagination-details">
      Showing
      {notEnoughResults ? (
        <select className="search__pagination-details-select" disabled={true}>
          <option>{resultContext.total_results}</option>
        </select>
      ) : (
        <select
          className="search__pagination-details-select"
          onChange={handleOnChange}
        >
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="48">48</option>
        </select>
      )}
      of {resultContext.total_results} results.
    </div>
  );
};

export default SearchPaginationDetails;
