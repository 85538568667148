const accordions = document.querySelectorAll('.tab-clickable');

if (accordions.length > 0) {
    
    accordions.forEach((accordion) => {

        accordion.addEventListener('click', function () {
            toggleAccordion(accordion);
        });

    });

    function toggleAccordion(accordion) {

        let content = accordion.nextElementSibling;

        if( content.classList.contains('tab-zone') ) {

            if(accordion.getAttribute('aria-expanded') == 'true' ) {
                accordion.classList.remove('active');
                accordion.setAttribute('aria-expanded', 'false');
                content.style.maxHeight = null;
            } else {
                accordion.classList.add('active');
                accordion.setAttribute('aria-expanded', 'true');
                content.style.maxHeight = content.scrollHeight + 'px';
            }

        } 

    }

}
