import React, { useContext, useEffect } from "react";
import ClearAll from "../Elements/ClearAll";
import { SearchContext } from "./Context/SearchContext";

const SearchProductTypes = ({ types, fullSearch }) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);

  const handleOnClick = (type, all) => {
    let data = {
      ...searchContext,
    };

    if( type.id ) {
      data.types = {
        id: type.id,
        label: type.label,
        slug: type.slug,
      };
    } else {
      data.types = [];
    }

    data.post_type = [];

    if ( type.slug ) {

      if( 
        type.slug.includes("seminars") || type.slug.includes("onsite")) {
      } else {
        data.formats = []
      }

    }

    data.current_page = 1;

    setSearchContext(data);
  };

  const handlePostTypeClick = (post_type) => {
    let data = {
      ...searchContext,
    };
    
    data.types = [];
    data.post_type = post_type;
    data.current_page = 1;

    setSearchContext(data);
  };

  return (
    <div className="search__product-types">

      { fullSearch ? (
        <button
          className={
            "search__product-type" +
            (searchContext.types.length === 0 && searchContext.post_type.length === 0
              ? " search__product-type--selected"
              : "")
          }
          onClick={() => handleOnClick([])}
        >
          All
        </button>
      ) : (
        <button
          className={
            "search__product-type" +
            (searchContext.types.length === 0
              ? " search__product-type--selected"
              : "")
          }
          onClick={() => handleOnClick([])}
        >
          All
        </button>
      ) }
    
      {types.map((type) => {
        return (
          <button
            key={type.id}
            className={
              "search__product-type" +
              (searchContext.types.id === type.id
                ? " search__product-type--selected"
                : "")
            }
            onClick={() => handleOnClick(type)}
          >
            {type.label}
          </button>
        );
      })}

      { fullSearch ? (
        <>
           <button
            className={
              "search__product-type" +
              (searchContext.post_type === 'page'
                ? " search__product-type--selected"
                : "")
            }
            onClick={() => handlePostTypeClick('page')}
          >
            Pages
          </button>
          <button
            className={
              "search__product-type" +
              (searchContext.post_type === 'post'
                ? " search__product-type--selected"
                : "")
            }
            onClick={() => handlePostTypeClick('post')}
          >
            Blog Posts
          </button>
        </>
      ) : null }
     
      <ClearAll />
    </div>
  );
};

export default SearchProductTypes;
