import React from "react";

const ArrowLeftIcon = () => {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.823 7.005a.678.678 0 0 0-.202-.5.677.677 0 0 0-.498-.2l-2.24.002.63-.63a.664.664 0 0 0 .191-.49.664.664 0 0 0-.192-.49.664.664 0 0 0-.49-.193.664.664 0 0 0-.49.193l-1.82 1.821a.618.618 0 0 0-.148.228.78.78 0 0 0-.043.262c0 .094.014.181.043.263.03.082.08.157.15.227l1.82 1.819a.664.664 0 0 0 .491.192.664.664 0 0 0 .49-.193.664.664 0 0 0 .192-.49.664.664 0 0 0-.193-.49l-.63-.63h2.24a.676.676 0 0 0 .498-.203.676.676 0 0 0 .201-.498Zm4.2-.004c0 .969-.182 1.879-.55 2.73a7.063 7.063 0 0 1-1.494 2.225c-.63.63-1.37 1.13-2.221 1.497a6.812 6.812 0 0 1-2.73.554 6.813 6.813 0 0 1-2.73-.55 7.062 7.062 0 0 1-2.224-1.494A7.073 7.073 0 0 1 .576 9.74a6.82 6.82 0 0 1-.553-2.73c0-.968.182-1.878.55-2.73a7.074 7.074 0 0 1 1.494-2.223c.63-.63 1.37-1.13 2.221-1.498a6.812 6.812 0 0 1 2.73-.553c.968-.001 1.879.182 2.73.55a7.065 7.065 0 0 1 2.224 1.493c.63.63 1.13 1.37 1.498 2.222.368.851.553 1.761.553 2.73Zm-1.4.002c0-1.552-.547-2.873-1.639-3.963C9.893 1.95 8.571 1.405 7.02 1.407c-1.552 0-2.873.547-3.963 1.638-1.09 1.092-1.634 2.414-1.633 3.966.001 1.551.548 2.872 1.639 3.962 1.092 1.09 2.414 1.635 3.965 1.634 1.552-.002 2.873-.548 3.963-1.64 1.09-1.091 1.634-2.413 1.633-3.964Z"
        fill="#315DA6"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
