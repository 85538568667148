
const call_api = async (action, data = {}) => {
	const form_data = new FormData();
	form_data.append('security', wp_ajax.security);
	form_data.append('data', JSON.stringify(data));
	
	return fetch(`${wp_ajax.ajax_url}?action=${action}`, {
		method: 'POST',
		body: form_data
	})
	.then(response => response.json())
	.catch(error => {
		console.log('Error during fetch: ' + error);
	});
}

export const get_search_results = async (data) => {
	return await call_api('search_results', data)
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});
}

export const get_full_search_results = async (data) => {
	return await call_api('full_search_results', data)
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});
}

export const get_search_filters = async () => {
	return await call_api('search_filters')
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});
}
