import React from "react";

const ArrowDownIcon = () => {
  return (
    <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.333 0C3.738 0 0 3.74 0 8.334c0 4.594 3.738 8.333 8.333 8.334 4.595 0 8.334-3.739 8.334-8.334S12.929.001 8.333 0zm0 15a6.675 6.675 0 0 1-6.666-6.666 6.676 6.676 0 0 1 6.666-6.667A6.676 6.676 0 0 1 15 8.334a6.674 6.674 0 0 1-6.667 6.667z"
        fill="#315DA6"
      />
      <path
        d="M9.167 5.006H7.5v3.333H4.993l3.337 3.338 3.337-3.338h-2.5V5.006z"
        fill="#315DA6"
      />
    </svg>
  );
};

export default ArrowDownIcon;
