import React, { useContext, useState, useEffect } from 'react';
import { DistanceContext } from "./Context/DistanceContext";
import { DistanceContextDefaultData } from "./Context/DistanceContextDefaultData";
import { getDistance } from '../Services/LocationAPI';
import Spinner from '../Icons/Spinner';

const Distance = ({ to }) => {
    
    const [distanceContext, setDistanceContext] = useContext(DistanceContext);
    const [distance, setDistance] = useState(null);
    const [locationFrom, setLocationFrom] = useState(false);
    const [loaded, setLoaded] = useState(true);

    useEffect(() => {

        if( distanceContext.from && distanceContext.from[0]) {
            setLocationFrom(distanceContext.from[0])
        }

    }, []);

    useEffect(() => {
        setLocationFrom(distanceContext.from);
    }, [distanceContext]);

    useEffect(() => {
    
        if (
            locationFrom &&
            to && 
            locationFrom[0].lat &&
            locationFrom[0].lng &&
            to[0].lat &&
            to[0].lng
        ) {
            setLoaded(false);
            getDistance(
                locationFrom[0],
                to[0]
            ).then((response) => {
                setLoaded(true);
                setDistance(response);
            });

        }

    }, [locationFrom]);

    return (
        <div className={`distance`}>

            { loaded ? (
                <>
                    { distance ? (
                    <>
                        { distance } miles
                    </>
                ) : null }
                </>
            ) : <Spinner/> }
            
        </div>
    );
};

export default Distance;