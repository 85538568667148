import React, { useState, useEffect } from "react";
import { addProductToBasket, addEventToBasket } from '../Services/BasketAPI';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import Spinner from '../Icons/Spinner';
import AddToCartModal from './AddToCartModal';

const AddToCart = ({ type, id, quantity, label, addedLabel, classes, arrow }) => {

    const [inCart, setInCart] = useState(false);
    const [loading, setLoading ] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const addToCartEvent = new CustomEvent('addToCartEvent');

    const changeVisibility = ( status ) => {
        setModalVisible(status);
    }

    const addToBasket = async () => {
        
        if( !loading && !inCart ) {

            setLoading(true);

            if( type === 'event' ) {

                addEventToBasket(
                    id,
                    quantity
                ).then(() => {
                    setInCart(true);
                    setLoading(false);
                    setModalVisible(true);
                    document.dispatchEvent(addToCartEvent);
                });
    
            } else {
    
                addProductToBasket(
                    id,
                    type,
                    'block'
                ).then(() => {
                    setInCart(true);
                    setLoading(false);
                    setModalVisible(true);
                    document.dispatchEvent(addToCartEvent);
                });
    
            }

        }
        
    };

    useEffect(() => {
        
        const itemsInCart = sessionStorage.getItem('cartItems');
        if( itemsInCart && itemsInCart.includes(id) ) {
            setInCart(true);
        }

    }, []);

    return (

        <>
            <span onClick={() => { addToBasket() } } className={`add-to-cart ` + classes} data-incart={ inCart ? true : false} data-loading={ loading ? 'true' : 'false'} data-active={ inCart ? 'true' : 'false'} data-loading={ loading ? 'true' : 'false'}>

                { loading ? (
                    <Spinner/>
                ) : null }

                <span className="add-to-cart__content">
                    { inCart ? (
                        <> { addedLabel ? addedLabel : 'Added to cart' } </>
                    ) : (
                        <>{ label ? label : 'Add to cart'}</>
                    ) }

                    { arrow ? ( 
                        <ChevronRightIcon fill="#ffffff"/>
                    ) : null }

                </span>

            </span>
            
            { modalVisible ? (
                <AddToCartModal visible={modalVisible} changeVisibility={changeVisibility}/>
            ) : null }
            
        </>

    )
};

export default AddToCart;
