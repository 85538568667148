import React, { useContext } from "react";
import { SearchContext } from "./Context/SearchContext";

const ActiveFilters = ( { filters, active, label, type } ) => {
    
    const [searchContext, setSearchContext] = useContext(SearchContext);

    const resetFilter = (type, value) => {
        
        let data = {
            ...searchContext,
        };

        const index = data[type].indexOf(value);
        data[type].splice(index, 1);

        setSearchContext(data);
    }


    return (
        <>
            { filters && active ? (
                <>
                    { filters.map((filter) => {
                        return active.includes(filter.id) ? (
                            <div className="search-pill" key={filter.id}>
                                {filter.label}
                                <span className="search-pill__close" onClick={()=>resetFilter(type, filter.id)}>&times;</span>
                            </div>
                        ) : null ;
                    }) }
                </>
            ) : null }
            
        </>
    );
};

export default ActiveFilters;