export function updateCart() {

    const cart = document.querySelector('[data-behaviour="cart"]');

    if( cart ) {

        const cartUrl = cart.getAttribute("href");

        if(cartUrl) {

            fetch(`${cartUrl}/CartApi/GetUserCart`, {
                credentials: 'include'
            })
                .then(function(response) {
                    return response.json();
                })
                .then(function(data) {
                    if(data.cartinfo.ItemsCount > 0) {
                        cart.classList.add('has-items');
                        cart.setAttribute('data-count', data.cartinfo.ItemsCount);
                        sessionStorage.setItem('cartItems', data.cartinfo.Items);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        }
   
    }

}

updateCart();

document.addEventListener('addToCartEvent', () => {
    updateCart();
});
