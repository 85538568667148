import React from 'react';
import ChevronRightIcon from '../../Icons/ChevronRightIcon';
import Spinner from '../../Icons/Spinner';

const BasketButton = ({ label, disabled, onClick, adding }) => {
    return (
        <div className={`r-basket__button ${disabled ? "is-disabled" : ""}`}>
            <button disabled={disabled} onClick={onClick}>
                {adding ? (
                   <Spinner/>
                ) : (
                    <>
                        {label}
                        <ChevronRightIcon />
                    </>
                )}
                
            </button>
            {disabled ? (
                <span className="r-basket__tooltip">Please select an option</span>
            ): null }
        </div>
    );
};

export default BasketButton;
