import React, { useState } from "react";
import SeminarView from "./Views/SeminarView";
import WebinarView from "./Views/WebinarView";
import { DistanceContext } from "../Distance/Context/DistanceContext";
import { DistanceContextDefaultData } from "../Distance/Context/DistanceContextDefaultData";

export const DistanceProvider = ({ children }) => {
  const distanceState = useState({
    ...DistanceContextDefaultData,
  });

  return (
    <DistanceContext.Provider value={distanceState}>
      {children}
    </DistanceContext.Provider>
  );
};

const Basket = ({ config, ipconfig, inperson }) => {
  if (config.type === "seminar") {
    return (
      <DistanceProvider>
        <div className="r-basket">
          { Object.keys(ipconfig).length > 0 ? (
            <SeminarView config={config} ipconfig={ipconfig} inperson={inperson} />
          ) : (
            <SeminarView config={config} />
          ) }
        </div>
      </DistanceProvider>
    );
  }

  if (config.type === "webinar") {
    return (
      <div className="r-basket">
        <WebinarView config={config} />
      </div>
    );
  }

  return <div className="r-basket">TODO: Implement {config.type}</div>;
};

export default Basket;
