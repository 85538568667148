import React, { useEffect, useState, useRef } from "react";
import { getDynamicPrice } from '../Services/BasketAPI';

const DynamicPrice = ({ config }) => {

    const [price, setPrice] = useState(config.price);
    const [dynamicPrice, setDynamicPrice] = useState(null);
    const [currency, setCurrency] = useState('$');
    const mountedRef = useRef(false)

    const getDynamicPricing = async () => {
        let newPrice = await getDynamicPrice(config.code, config.type);
        if(newPrice && mountedRef.current) {
            if(config.useGroup) {
                setDynamicPrice(newPrice.GroupPrice);
            } else {
                setDynamicPrice(newPrice.Price);
            }
        }
    }
    
    const getPrice = async () => {
        let newPrice = await getDynamicPrice(config.code, config.type, true, true);
        if(newPrice && mountedRef.current) {

            if(newPrice.Country == 2) {
                setCurrency('C $');
            }

            if(config.useGroup) {
                setPrice(newPrice.GroupPrice);
            } else {
                setPrice(newPrice.Price);
            }
        }
    }

    // Tracking mounted state
    useEffect(() => {
        mountedRef.current = true
        return () => {
            mountedRef.current = false
        }
    }, [])

    useEffect(() => {
        if (mountedRef.current && price && config.code) {
            getPrice();
            getDynamicPricing();
        }
    }, []);

    return (
        <>
            { dynamicPrice && price && dynamicPrice < price ? (
                <>
                    <strike>{currency}{price ? price : null}</strike>
                    {dynamicPrice ? <>{currency}{dynamicPrice}</> : null}
                </>
            ) : (
                <>{price ? <>{currency}{price}</> : null}</>
            ) }
        </>
    );

};

export default DynamicPrice;