import React, { useContext, useState } from 'react';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import { SearchContext } from './Context/SearchContext';

const SearchHeaderCategory = ({ category, searchFilters }) => {
    const [categoryIsOpen, setDropdownIsOpen] = useState(false);
    const [searchContext, setSearchContext] = useContext(SearchContext);

    let timeout = null;

    const setCategory = (value) => {
        let data = {
            ...searchContext,
        };

        data.category = value;
        data.current_page = 1;
        setSearchContext(data);
        setDropdownIsOpen(false);
    };

    let categoryText = 'All Categories';

    if (searchContext.category) {
        let category = searchFilters.categories.find((c) => {
            return c.id === searchContext.category;
        });

        categoryText = category.label;
    }

    const handleOnMouseLeave = () => {
        timeout = setTimeout(() => {
            setDropdownIsOpen(false);
        }, 500);
    };

    const handleOnMouseEnter = () => {
        clearTimeout(timeout);
    };

    return (
        <div
            className="search__header-category"
            onMouseLeave={handleOnMouseLeave}
            onMouseEnter={handleOnMouseEnter}
        >
            <button
                onClick={() => setDropdownIsOpen(!categoryIsOpen)}
                aria-label="Search"
                className="button button--search-all button--icon button--icon-left form__button-input"
            >
                {categoryText} <ArrowDownIcon />
            </button>

            {searchFilters.categories && categoryIsOpen ? (
                <ul className="c-search__list">
                    <li
                        className="c-search__list-item"
                        onClick={() => setCategory(null)}
                    >
                        All Categories
                    </li>
                    {searchFilters.categories.map((category) => {
                        return (
                            <li
                                className="c-search__list-item"
                                key={category.id}
                                onClick={() => setCategory(category.id)}
                            >
                                {category.label}
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </div>
    );
};

export default SearchHeaderCategory;
