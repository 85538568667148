import React, { useState } from 'react';
import BuyForMyTeamTab from './Tabs/BuyForMyTeamTab';
import IndividualAccessTab from './Tabs/Webinar/IndividualAccessTab';

const WebinarView = ({ config }) => {
    const [activeTab, setActiveTab] = useState(1);

    let tabContent = null;

    if (activeTab === 1) {
        tabContent = <IndividualAccessTab config={config} />;
    }

    if (activeTab === 2) {
        tabContent = <BuyForMyTeamTab config={config} />;
    }

    return (
        <div>
            <div className="r-basket__tabs">
                {tabContent}
            </div>
        </div>
    );
};

export default WebinarView;
