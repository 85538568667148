import React, { useContext, useState } from "react";
import SearchBar from "./SearchBar";
import SearchHeaderCategory from "./SearchHeaderCategory";
import SearchProductTypes from "./SearchProductTypes";

const SearchHeader = ({ config, searchFilters }) => {
  return (
    <header className="search__header">
      <div className="search__header-bar">
        {config.hideCategory ? null : (
          <SearchHeaderCategory searchFilters={searchFilters} />
        )}
        {config.hideSearchBar ? null : <SearchBar />}
      </div>
      {config.hideProductTypes !== true && searchFilters.types ? (
        <SearchProductTypes fullSearch={config.fullSearch ? true : false} types={searchFilters.types ?? []} />
      ) : null}
    </header>
  );
};

export default SearchHeader;
