const call_api = async (action, data = {}) => {
	const form_data = new FormData();
	form_data.append('data', JSON.stringify(data));
	
	return fetch(`${wp_ajax.ajax_url}?action=${action}`, {
		method: 'POST',
		body: form_data
	})
	.then(response => response.json())
	.catch(error => {
		console.log('Error during fetch: ' + error);
	});
}

export const getUserLocation = async (postalCode) => {
    return await call_api('get_user_location', {
		postCode: postalCode ? postalCode : false,
	})
	.then(response => {
		if (!response.success) {
			console.log(response);
			return response;
		} else {
			localStorage.setItem('userLocation', response.data);
			return response;
		}
	});
}

export const getDistance = async (from, to) => {
	return getDistanceFromLatLon(from.lat, from.lng, to.lat, to.lng);
};

const getDistanceFromLatLon = (lat1, lon1, lat2, lon2) => {

	var lat1 = parseFloat(lat1);
	var lon1 = parseFloat(lon1);
	var lat2 = parseFloat(lat2);
	var lon2 = parseFloat(lon2);

	var R = 6371; // Radius of the earth in km
	var dLat = deg2rad(lat2-lat1);  // deg2rad below
	var dLon = deg2rad(lon2-lon1); 
	var a = 
	  Math.sin(dLat/2) * Math.sin(dLat/2) +
	  Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
	  Math.sin(dLon/2) * Math.sin(dLon/2)
	  ; 
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	var d = R * c; // Distance in km
	var miles = convertKmToMiles(d);

	return parseInt(miles);
}

const convertKmToMiles = (km) => {
	return km * 0.621371;
}

const deg2rad = (deg) =>  {
	return deg * (Math.PI/180)
}