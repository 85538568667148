import React from 'react';
import { addPryorPlusToBasket } from '../../Services/BasketAPI';
import DynamicPrice from '../../DynamicPrice';

const PryorPlusPopup = ({ config: { content } }) => {

    const handleOnAdd = () => {
        addPryorPlusToBasket().then((response) => {
            window.location.href = 'https://cart.pryor.com/Checkout';
        });
    };

    const priceConfig = {
        "code": content.itemid,
        "type": "product",
        "price": content.price
    }

    return (
        <div className="r-basket__popup-wrapper">
            <div className="r-basket__popup pryorplus-popup">

                <div className="pryorplus-popup__text">

                    { content.intro ? (
                        <p className="pryorplus-popup__intro" dangerouslySetInnerHTML={{ __html: content.intro }}/>
                    ): null }

                    { content.title ? (
                        <h3 className="pryorplus-popup__title">{content.title}</h3>
                    ): null }

                    { content.cta ? (
                        <span className="pryorplus-popup__cta">{content.cta}</span>
                    ): null }

                    { content.features ? (
                        <ul className="pryorplus-popup__features">
                            {content.features.map((feature, idx) => {
                                return (
                                    <li key={idx}>
                                        {feature.text}
                                    </li>
                                );
                            })}
                        </ul>
                    ): null }

                    <div className="r-basket__popup-ctas">
                        <button type="button" onClick={handleOnAdd} className="button button--orange-gradient">
                            Add to cart for <DynamicPrice config={priceConfig} /> per person
                        </button>
                        <a
                            title="Continue without adding to basket"
                            href="https://cart.pryor.com/Checkout"
                        >
                            Continue without adding to basket
                        </a>
                    </div>

                </div>

                { content.featured_image ? (
                    <div className="pryorplus-popup__media">
                        <img className="pryorplus-popup__image" src={content.featured_image}/>
                    </div>
                ): null }
                
            </div>
        </div>
    );
};

export default PryorPlusPopup;
