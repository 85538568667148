import React from "react";

const SearchLoadingResults = (props) => {
  return (
    <div className="search__loading-results">
      <p className="search__loading-results-text">Loading your results...</p>
    </div>
  );
};

export default SearchLoadingResults;
