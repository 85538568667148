import React, { useState, useEffect } from "react";

const AddToCartModal = ( { visible, changeVisibility } ) => {

    const [isVisible, setIsVisible] = useState(visible);

    const closeCartModal = () => {
        changeVisibility(false);
    };

    const goToCart = () => {
        window.location.href = 'https://cart.pryor.com/Checkout';
    };

    return (

        <>
            { isVisible ? (
                <div className="cart-modal">
                    <div className="cart-modal__content">
                        
                        <p className="cart-modal__title">Added to your Cart</p>

                        <div className="cart-modal__ctas">
                            <span className="button button--gradient-outline-blue" onClick={() => { closeCartModal() } }>Continue Shopping</span>
                            <span className="button button--orange-gradient" onClick={() => { goToCart() } }>Ready to Checkout</span>
                        </div>

                        <span className="cart-modal__close" onClick={() => { closeCartModal() } } >&times;</span>
                    </div>
                </div>
            ) : null }
        </>
        
    )
};

export default AddToCartModal;


