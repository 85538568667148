import React, { useEffect, useState, useContext } from "react";
import ArrowDownIcon from "../Icons/ArrowDownIcon";
import { SearchContext } from "./Context/SearchContext";

const SearchOrder = (types) => {

  const defaultTypes = {
    popularity: "Sort by Popularity",
    date: "Sort by Date",
    title: "Sort by Title"
  };

  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [orderOptions, setOrderOptions] = useState(defaultTypes);

  let timeout = null;

  const sortBy = (value) => {
    let data = {
      ...searchContext,
    };
    data.sort = value;
    data.current_page = 1;
    setSearchContext(data);
    setDropdownIsOpen(false);
  };

  const handleOnMouseLeave = () => {
    timeout = setTimeout(() => {
      setDropdownIsOpen(false);
    }, 500);
  };

  const handleOnMouseEnter = () => {
    clearTimeout(timeout);
  };

  const setOrderByOptions = (filterType) => {

    let sortValues = [
      'popularity',
      'date',
      'title'
    ];

    let sortOptions = defaultTypes;
    
    if( filterType.length === 0 ) {
      sortBy(sortValues[0]);
      setOrderOptions(defaultTypes);
      return defaultTypes;
    }
    
    const currentType = searchContext.types.slug;

    if ( currentType ) {

      if( currentType.includes("seminars") || currentType.includes("onsite")) {
        sortOptions = {
          popularity: "Sort by Popularity",
          date: "Sort by Date",
          title: "Sort by Title"
        };
      }
  
      if( currentType.includes("webinars") || currentType.includes("digital-download")) {
        sortValues = [
          'date',
          'title'
        ];
        sortOptions = {
          date: "Sort by Date",
          title: "Sort by Title",
        };
      }
  
      if( currentType.includes("physical-product") ) {
        sortValues = [
          'title'
        ];
        sortOptions = {
          title: "Sort by Title",
        };
      }

    }
    
    if( !sortValues.includes(searchContext.sort)  ) {
      sortBy(sortValues[0]);
    }

    setOrderOptions(sortOptions);

  };

  useEffect(() => {
    setOrderByOptions(searchContext.types);
  }, [searchContext.types]);

  return (
    <div
      className="search__order"
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
    >
      <button
        className="search__button"
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
      >
        {orderOptions[searchContext.sort]} <ArrowDownIcon />
      </button>
      {dropdownIsOpen ? (
        <ul className="c-search__list">
          {Object.keys(orderOptions).map((key) => {
            return (
              <li
                key={key}
                className="c-search__list-item"
                onClick={() => sortBy(key)}
                >
                { orderOptions[key] }
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default SearchOrder;
