import React from "react";

const PlusIcon = ({ className }) => {
  return (
    <svg
      width={18}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m11.666 7.992-5.333.004M9 5.327v5.334"
        stroke="#315DA6"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
      <path
        d="M8.995 1.006a7 7 0 1 0 .011 14.001 7 7 0 0 0-.01-14Zm.01 12.813a5.813 5.813 0 0 1-.009-11.625 5.813 5.813 0 0 1 .008 11.625Z"
        fill="#315DA6"
      />
    </svg>
  );
};

export default PlusIcon;
