import React, { useState } from 'react';
import BuyForMyTeamTab from './Tabs/BuyForMyTeamTab';
import IndividualAccessTab from './Tabs/Seminar/IndividualAccessTab';

const SeminarView = ({ config, ipconfig, inperson }) => {
    const [activeTab, setActiveTab] = useState(inperson ? 2 : 1);

    let tabContent = <IndividualAccessTab config={config} />;

    if (activeTab === 2) {
        tabContent = <IndividualAccessTab config={ipconfig} inPerson={true} />;
    }
        
    return (
        <div>
            <div className="r-basket__tabs">

                { ipconfig && ipconfig.date_options && ipconfig.date_options.length > 0 ? (
                    <div className="r-basket__header">
                        <p className="r-basket__heading">Choose your preferred training format</p>
                        <div className="r-basket__types">
                            <div
                                className={
                                    'r-basket__type' +
                                    (activeTab === 1
                                        ? ' r-basket__type--selected '
                                        : '')
                                }
                                onClick={() => setActiveTab(1)}
                            >
                                Live Virtual
                            </div>
                            <div
                                className={
                                    'r-basket__type' +
                                    (activeTab === 2
                                        ? ' r-basket__type--selected '
                                        : '')
                                }
                                onClick={() => setActiveTab(2)}
                            >
                                In-Person
                            </div>
                        </div>
                    </div>
                ) : null }

                {tabContent}
            </div>
        </div>
    );
};

export default SeminarView;
