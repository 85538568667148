import React from 'react';
import Distance from '../../Distance';

const RadioOption = ({ setSelected, isSelected, option, isCalendar, inPerson }) => {
    
    let location = false;
    let id = option.label;

    if (option.latlng) {
        location = [
            {
                lng: option.latlng.coordinates[0],
                lat: option.latlng.coordinates[1]
            }
        ];
    }

    /** 
     * Conditional check to account for training products that 
     * don't have codes and have labels that match
     */
    if( option.code ) {
        id = option.code;
    } else if ( option.itemId ) {
        id = option.itemId;
    } else {
        id = option.label
    }

    return (
        <div className="r-basket__tabs-content-radio radio">
            <input
                id={id}
                value={id}
                type="radio"
                onChange={() => setSelected(option.id)}
                checked={isSelected ? 'selected' : false}
            />
            <label
                className={isCalendar ? 'calendar' : ''}
                htmlFor={id}
            >
                {option.label} 

                { inPerson ? (
                    <>
                        {option.city ? (
                            <span className="radio__sub">&nbsp; &mdash; {option.city}</span>
                        ) : null}
                        {option.state ? (
                            <span className="radio__sub">, {option.state}</span>
                        ) : null}
                    </>
                 ) : (
                    <>
                        {option.duration ? (
                            <span className="radio__sub">&nbsp; &mdash; {option.duration}</span>
                        ) : null}
                        {option.timezone ? (
                            <strong className="radio__sub">
                                &nbsp; {option.timezone}
                            </strong>
                        ) : null}
                        {option.mediaType ? <span className="r-basket__item-id">{option.mediaType}</span> : null}
                        {option.itemId ? <span className="r-basket__item-id">Item #: {option.itemId}</span> : null}
                        {option.description ? <span className="r-basket__description">
                            { option.description }
                            { option.date ? <> {option.date} { option.time ? <> at {option.time} CT</> : null } </>: null }
                        </span> : null }
                        {option.itemId || option.expandedDescription ? <span>
                            {option.expandedDescription ? <span className="r-basket__item-id">{option.expandedDescription}</span> : null}
                        </span> : null }
                    </>
                )}
                
            </label>
            
            { inPerson && location  ? (
                <Distance to={location} />
            ) : null }

        </div>
    );
};

export default RadioOption;
