import React from "react";

const Rating = ({ rating, total }) => {

  const ratingData = {
    '--rating': rating,
    '--total': total
 }

  return <div className="star-rating" style={ratingData}></div>;
};

export default Rating;
