import React, { useState } from "react";
import PropTypes from "prop-types";
import PlusIcon from "../Icons/PlusIcon";
import MinusIcon from "../Icons/MinusIcon";

const propTypes = {
  name: PropTypes.string.isRequired,
};

const SearchFilter = ({ name, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="search__filter">
      <div className="search__filter-toggle" onClick={() => setIsOpen(!isOpen)}>
        <span className="search__filter-label">Filter by {name}</span>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      {isOpen ? <div className="search__filter-options">{children}</div> : null}
    </div>
  );
};

SearchFilter.propTypes = propTypes;

export default SearchFilter;
