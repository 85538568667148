const expanders = document.querySelectorAll(
    '[data-behaviour="expander"]'
);

if (expanders.length > 0) {
   
    expanders.forEach((expander) => {

        const toggle = expander.querySelector('.expander__toggle');
        const content = expander.querySelector('.expander__content');

        toggle.addEventListener('click', function (ev) {
            toggleExpander(expander, toggle, content);
            ev.preventDefault();
        });

    });

    const toggleExpander = (expander, toggle, content) => {
        
        if (content.style.maxHeight) {
            expander.classList.remove('expander--open');
            content.style.maxHeight = null;
            toggle.innerHTML = toggle.dataset.open ? toggle.dataset.open : 'Read Less';
            content.setAttribute('aria-hidden', 'true');
        } else {
            expander.classList.add('expander--open');
            content.style.maxHeight =
            content.scrollHeight + 'px';
            toggle.innerHTML = toggle.dataset.close ? toggle.dataset.close : 'Read More';
            content.setAttribute('aria-hidden', 'false');
        }
 
    };

}
