import React, { useState, useEffect, useContext } from "react";
import SearchHeader from "./SearchHeader";
import SearchBody from "./SearchBody";
import { get_search_filters } from "../Services/SearchAPI";

const SearchContainer = ({ config, cta, types }) => {
  const [searchFilters, setSearchFilters] = useState({});

  useEffect(async () => {
    let searchFilters = await get_search_filters();
    setSearchFilters(searchFilters);
  }, []);

  return (
    <div className="search">
      <SearchHeader config={config} searchFilters={searchFilters} />
      <SearchBody config={config} searchFilters={searchFilters} cta={cta} types={types} />
    </div>
  );
};

export default SearchContainer;
