import React, { useContext, useEffect } from "react";
import ClearAll from "../Elements/ClearAll";
import { SearchContext } from "./Context/SearchContext";

const SearchFormats = ({ types }) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);

  const handleOnClick = (type, all) => {
    let data = {
      ...searchContext,
    };

    if( type.id ) {
      data.formats = [type.id];
    } else {
      data.formats = [];
    }

    data.current_page = 1;

    setSearchContext(data);
  };

  return (

    <>
      { types.length > 0 ? 

<div className="search__toggle-group search-toggle-group">

<button
  className={
    "search-toggle-group__item" +
    ( searchContext.formats.length == 0
      ? " search-toggle-group__item--selected"
      : "")
  }
  onClick={() => handleOnClick([])}
>
  All
</button>

{types.map((type) => {
return (
  <button
    key={type.id}
    className={
      "search-toggle-group__item" +
      (searchContext.formats && searchContext.formats.includes(type.id)
        ? " search-toggle-group__item--selected"
        : "")
    }
    onClick={() => handleOnClick(type)}
  >
    {type.label}
  </button>
);
})}

</div>

      : null}
    </>
    
  );
};

export default SearchFormats;
