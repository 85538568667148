import React from "react";

const ReturnIcon = ({ className }) => {
  return (
    <svg
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      className={className}
    >
      <g transform="matrix(.97643 0 0 .98399 -.302 -.652)">
        <path
          style={{
            fill: "none",
          }}
          d="M.31.663h10.427v11.012H.31z"
        />
        <clipPath id="a">
          <path d="M.31.663h10.427v11.012H.31z" />
        </clipPath>
        <g clipPath="url(#a)">
          <path
            d="M4.144 10.794V8.568h4.018c1.274 0 1.61-.476 1.61-1.61V1.372H6.608v4.032H4.144V3.178L.714 6.986l3.43 3.808Zm-.588-1.526L1.498 6.986l2.058-2.282v1.288h3.64V1.96h1.988v4.998c0 .854-.168 1.022-1.022 1.022H3.556v1.288ZM15.147 10.5V.7h5.572v1.148h-4.298v3.206h4.298v1.148h-4.298v3.15h4.298V10.5h-5.572Zm7.559 0V4.046h1.218v1.19c.178-.42.458-.751.84-.994.392-.243.864-.364 1.414-.364 1.606 0 2.408.882 2.408 2.646V10.5h-1.218V7.056c0-.728-.13-1.265-.392-1.61-.252-.345-.662-.518-1.232-.518-.588 0-1.04.191-1.358.574-.308.383-.462.91-.462 1.582V10.5h-1.218Zm10.441-1.022c.336 0 .606-.023.812-.07v1.078c-.28.065-.64.098-1.078.098-.196 0-.369-.009-.519-.028a1.991 1.991 0 0 1-.503-.154 1.341 1.341 0 0 1-.49-.35 2.047 2.047 0 0 1-.336-.658 3.37 3.37 0 0 1-.141-1.036v-3.29h-1.161V4.046h1.161V2.002h1.219v2.044h1.623v1.022h-1.623v3.024c0 .569.083.943.251 1.12.169.177.43.266.785.266Zm7.932-2.576c0 .177-.014.42-.042.728h-4.998c.009.616.196 1.101.56 1.456.364.345.854.518 1.47.518.858 0 1.428-.317 1.708-.952h1.218c-.112.597-.439 1.083-.98 1.456-.542.373-1.19.56-1.946.56-.99 0-1.778-.303-2.366-.91-.579-.616-.868-1.447-.868-2.492 0-1.027.289-1.848.868-2.464.578-.616 1.348-.924 2.31-.924.914 0 1.652.28 2.212.84.569.56.854 1.288.854 2.184Zm-5.04-.21h3.836c0-.569-.168-1.017-.504-1.344-.327-.327-.784-.49-1.372-.49-.579 0-1.046.168-1.4.504-.346.336-.532.779-.56 1.33Zm6.615 3.808V4.046h1.218v1.47c.121-.513.359-.901.714-1.162a1.889 1.889 0 0 1 1.176-.406c.252 0 .448.019.588.056v1.12a3.274 3.274 0 0 0-.602-.042c-.607 0-1.074.243-1.401.728-.317.485-.475 1.106-.475 1.862V10.5h-1.218Z"
            style={{
              fillRule: "nonzero",
            }}
            transform="matrix(1.02414 0 0 1.01627 .058 .088)"
          />
        </g>
      </g>
    </svg>
  );
};

export default ReturnIcon;
