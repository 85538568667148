import React, { useState, createRef } from "react";
import { submitBasketCallback } from "../../../Services/BasketAPI";
import BasketButton from "../../Components/BasketButton";

const BuyForMyTeamTab = ({ config }) => {
  const [isSent, setIsSent] = useState(false);
  const [showError, setShowError] = useState(false);

  let form = createRef();

  if (isSent) {
    return (
      <div>
        <h4 className="u-text--red">Thank You.</h4>
        <p>Your enquiry has been submitted, someone will be in touch.</p>
        <BasketButton label="Sent Successfully" disabled={true} />
      </div>
    );
  }

  const submitForm = () => {
    let data = {
      product: `[${config.product.code}] ${config.product.name}`,
    };

    let isValid = form.current.reportValidity();

    if (!isValid) {
      setShowError(true);
      return;
    }

    setShowError(false);

    form.current.querySelectorAll("input").forEach((input) => {
      data[input.getAttribute("name")] = input.value;
    });

    submitBasketCallback(data).then(() => {
      setIsSent(true);
    });
  };

  return (
    <>
      <div className="r-basket__form standard-form">
        <h4 className="r-basket__form-heading">
          Are you looking to take this course in a private setting for your
          team, online or onsite?
        </h4>
        <p className="r-basket__form-intro">
          Complete this form and a member of the Pryor team will contact you.
        </p>
        <form ref={form}>
          <div className="form-row">
            <div className="form-row--half">
              <label>
                First Name <span className="u-text--orange">*</span>
              </label>
              <input name="first_name" type="text" required />
            </div>

            <div className="form-row--half">
              <label>
                Last Name <span className="u-text--orange"> *</span>
              </label>
              <input name="last_name" type="text" required />
            </div>
          </div>

          <div className="form-group">
            <label>
              Company Name <span className="u-text--orange"> *</span>
            </label>
            <input name="company_name" type="text" required />
          </div>

          <div className="form-group">
            <label>
              How many people need training?{" "}
              <span className="u-text--orange">*</span>
            </label>
            <input name="total_participants" type="number" required />
          </div>

          <div className="form-group">
            <label>
              Company telephone Number <span className="u-text--orange">*</span>
            </label>
            <input name="telephone_number" type="text" required />
          </div>

          <div className="form-group">
            <label>
              Email Address at your Company{" "}
              <span className="u-text--orange">*</span>
            </label>
            <input name="email" type="email" required />
          </div>
        </form>
        {showError ? <p className="validation_message">Please fill in all required fields.</p> : null}
      </div>
      <BasketButton label="Request a Callback" onClick={submitForm} />
    </>
  );
};

export default BuyForMyTeamTab;
