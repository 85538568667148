import React from 'react';

const Price = ({ price, dynamicPrice = null, countryPrice = null, perPerson = true, selectedNumberOfParticipants = 1, groupPrice = null }) => {

    let currency = '$';

    if(countryPrice) {

        if(countryPrice.Price) {
            currency = 'C $'
            price = countryPrice.Price;
        }

    }

    let discount = null;

    if( dynamicPrice && dynamicPrice.Price && dynamicPrice.Price < Number(price)) {
        discount = dynamicPrice.Price;
    }

    if( dynamicPrice && dynamicPrice.GroupCount && dynamicPrice.GroupPrice && dynamicPrice.GroupCount <= selectedNumberOfParticipants) {
        price = groupPrice;
        discount = dynamicPrice.GroupPrice;
    }

    return (
        <div className="r-basket__tabs-content-price">
            { discount ? (
                <><strike>{currency}{price}</strike> <span className="">{currency}{discount}</span></>
            ) : (
                <>{currency}{price}</>
            ) }
            {perPerson ? (
                <span className="u-small-text">/person</span>
            ) : null}
        </div>
    );
};

export default Price;
