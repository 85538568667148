// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
/**
 * Throttles given function
 * @param {Number} delay 
 * @param {Function} func 
 * @returns Function
 */
 export function throttled(delay, func) {
	var lastTime = 0;
	return function () {
		var now = new Date();
		if (now - lastTime >= delay) {
			func();
			lastTime = now;
		}
	};
}

/**
 * Debounce function
 * @param {Object} callback 
 * @param {Number} wait 
 * @returns 
 */
 export function debounce(callback, wait) {
    let timeout;
    return (args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export function setCookie(name, value, expiresInHours) {
	const d = new Date();
	d.setTime(d.getTime() + (expiresInHours*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/;domain=.pryor.com;secure";
}

export function isOverflown(element) {
	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}