import React, { useEffect, useState, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import { SearchContextDefaultData } from "./Context/SearchContextDefaultData";
import { DistanceContext } from "./Context/DistanceContext";
import { DistanceContextDefaultData } from "./Context/DistanceContextDefaultData";
import { useDebounce } from '../Hooks/useDebounce';
import { getUserLocation } from '../Services/LocationAPI';

const PostCodeInput = () => {

    const [searchContext, setSearchContext] = useContext(SearchContext);
    const [distanceContext, setDistanceContext] = useContext(DistanceContext);
    const [postalCode, setPostalCode] = useState('');  

    useEffect(() => {
        
        const userLocation = localStorage.getItem('userLocation');
        if( userLocation ) {
            const locationParts = userLocation.split(',');
            if(locationParts[0]) {
                setPostalCode(locationParts[0]);

                let data = { ...distanceContext };
                const location = [{ lat: locationParts[1], lng: locationParts[2] }];
                data.from = location;
                data.error = false

                setDistanceContext(data);

            }
        } else {
            getUserLocation().then((response) => {
                
                if(response.success && response.data) {
                    
                    const locationParts = response.data.split(',');
                    if(locationParts[0]) {
                        setPostalCode(locationParts[0]);

                        let data = { ...distanceContext };
                        const location = [{ lat: locationParts[1], lng: locationParts[2] }];
                        data.from = location;
                        data.error = false
            
                        setDistanceContext(data);

                    }

                }
                
            });
        }

    }, []);

    const postalCodeChange = (event) => {
        const value = event.target.value;
        if(value) {
            setPostalCode(value);
            debouncedRequest();
        }
    };

    const debouncedRequest = useDebounce(() => {

        getUserLocation(
            postalCode,
        ).then((response) => {

            let data = { ...distanceContext };
                
            if(response && response.success && response.data) {

                const location = [{ lat: response['data'][1], lng: response['data'][2] }];
                data.from = location;
                data.error = false

            } else {
                data.error = true;
            }
            
            setDistanceContext(data);
        });

    });

    return (
        <div className={`postcode-input`}>
            <label className={`postcode-input__label`}>Postcode</label>
            <input className={`postcode-input__field ${distanceContext.error ? ' has-error': null }`} value={postalCode} onChange={postalCodeChange}/>
        </div>
    );

};

export default PostCodeInput;
