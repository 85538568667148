import React, { useState, useContext } from 'react';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import { ResultContext } from './Context/ResultContext';
import { SearchContext } from './Context/SearchContext';

const SearchPagination = ({ scrollToTop }) => {
    const [searchContext, setSearchContext] = useContext(SearchContext);
    const [resultContext, setResultContext] = useContext(ResultContext);

    if (resultContext.total_pages === null) {
        return <div></div>;
    }

    const handleOnClick = (page) => {
        let data = {
            ...searchContext,
        };

        data.current_page = page;
        setSearchContext(data);
        scrollToTop();
    };

    // todo: keep range to 9-ish (x either side)

    const start = Math.max(searchContext.current_page - 2, 1);
    const end = Math.min(resultContext.total_pages, start + 4);

    const pageButtons = [];

    for (let i = start; i <= end; i++) {
        pageButtons.push(
            <button
                key={i}
                disabled={searchContext.current_page === i}
                aria-label={`Navigate to page ${i}`}
                className={
                    'search__pagination-button' +
                    (searchContext.current_page === i
                        ? ' search__pagination-button--selected'
                        : '')
                }
                onClick={() => handleOnClick(i)}
            >
                {i}
            </button>
        );
    }

    return (
        <div className="search__pagination">
            <nav>
                <button
                    className="search__pagination-button"
                    disabled={searchContext.current_page === 1}
                    aria-label="Previous"
                    onClick={() => {
                        if (searchContext.current_page - 1 >= 1) {
                            handleOnClick(searchContext.current_page - 1);
                        }
                    }}
                >
                    <ChevronLeftIcon />
                </button>
                {start !== 1 ? (
                    <>
                        <button
                            className="search__pagination-button"
                            aria-label="Next"
                            onClick={() => handleOnClick(1)}
                        >
                            1
                        </button>
                        <span>...</span>
                    </>
                ) : null}
                {pageButtons}
                {end !== resultContext.total_pages ? (
                    <>
                        <span>...</span>
                        <button
                            className="search__pagination-button"
                            aria-label="Last Page"
                            onClick={() =>
                                handleOnClick(resultContext.total_pages)
                            }
                        >
                            {resultContext.total_pages}
                        </button>
                    </>
                ) : null}
                <button
                    className="search__pagination-button"
                    aria-label="Next"
                    disabled={
                        searchContext.current_page === resultContext.total_pages
                    }
                    onClick={() => {
                        if (
                            searchContext.current_page + 1 <=
                            resultContext.total_pages
                        ) {
                            handleOnClick(searchContext.current_page + 1);
                        }
                    }}
                >
                    <ChevronRightIcon />
                </button>
            </nav>
        </div>
    );
};

export default SearchPagination;
