import React, { useRef, useState, useContext } from "react";
import SearchFilters from "./SearchFilters";
import ActiveSearchFilters from "./ActiveSearchFilters";
import SearchResults from "./SearchResults";
import SearchPaginationDetails from "./SearchPaginationDetails";
import SearchOrder from "./SearchOrder";
import PostCodeInput from "./PostCodeInput";
import SearchPagination from "./SearchPagination";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import SearchFormats from "./SearchFormats";
import { SearchContext } from "./Context/SearchContext";

const SearchBody = ({ config, searchFilters, cta, types }) => {
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const bodyRef = useRef(null)
  const executeScroll = () => bodyRef.current.scrollIntoView()    
  
  return (
    <header className={`search__body${searchContext.formats && searchContext.formats.includes('in-person') ? " search__body--in-person" : ""}`} ref={bodyRef}>

      <SearchPaginationDetails fullSearch={config.fullSearch ? true : false} />

      { searchContext.formats.includes('in-person') ? (
        <PostCodeInput/>
      ) : null }

      <div className="search__body-filters">

        { config.hideFilters ? null : (
          <button
            className="search__button"
            onClick={() => setFilterIsOpen(true)}
          >
            Filter <ArrowRightIcon />
          </button>
        ) }

        {config.showFormatsToggle == true ? <>

          { searchContext && searchContext.types.length < 1 ?
            <SearchFormats types={searchFilters.formats ?? []}/>
          : null }

          { searchContext && searchContext.types && searchContext.types.slug ? <> {
              searchContext.types.slug.includes('onsite') ||
              searchContext.types.slug.includes('seminars')  ? <>
                <SearchFormats types={searchFilters.formats ?? []}/>
              </> : null }
          </> : null }

        </>: null }

        { config.hideSort ? null : (
          <SearchOrder types={types} />
        )}
    
      </div>
      { config.hideFilters ? null : (
        <ActiveSearchFilters filters={searchFilters} config={config}/>
      )}

      <SearchFilters
        isOpen={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        searchFilters={searchFilters}
        config={config}
        types={types}
      />
      <SearchResults cta={cta} fullSearch={config.fullSearch ? true : false} />

      <SearchPagination scrollToTop={executeScroll} />

    </header>
  );
};

export default SearchBody;
