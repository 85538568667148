const accordions = document.querySelectorAll(
    '.accordion-item__header'
);

if (accordions.length > 0) {

    accordions.forEach((accordion) => {
        accordion.addEventListener('click', function () {
            setAnswer(accordion);
        });
    });

    function setAnswer(accordion) {
        accordion.classList.toggle('active');
        let accordionItem = accordion.nextElementSibling;

        if (accordionItem.style.maxHeight)
            accordionItem.style.maxHeight = null;
        else
            accordionItem.style.maxHeight =
                accordionItem.scrollHeight + 'px';
    }
}
