import React, { useState, useEffect, useContext } from "react";
import Checkbox from "../Elements/Checkbox";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import { SearchContext } from "./Context/SearchContext";
import { ResultContext } from "./Context/ResultContext";
import SearchFilter from "./SearchFilter";

const SearchFilters = ({ isOpen, onClose, searchFilters, config, types }) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [resultContext, setResultContext] = useContext(ResultContext);
  const [showDuration, setShowDuration] = useState(true);
  const [showMonths, setShowMonths] = useState(false);
  const [showPriceFilter, setShowPriceFilter] = useState(!config.hidePriceFilter);
  const [showFormatsFilter, setShowFormatsFilter] = useState(config.showFormatsFilters);

  let priceTimeout = null;

  const handleClose = () => {
    if (!isOpen) {
      return;
    }

    onClose();
  };

  const monthVisibility = () => {

    let currentType = null;

    const matchedType = Object.keys(types).filter((key) => {
      return types[key].id === parseInt(searchContext.types.id);
    });

    if( types[matchedType] ) {
      currentType = types[matchedType].name.toLowerCase();
    }

    if( 
      currentType == "webinars" || 
      currentType == "seminars" ||
      currentType == "onsite"
    ) {
      setShowMonths(true);
    } else {
      setShowMonths(false);
    }

  };

  useEffect(() => {

    if(config.hideDurationFilter) {
      setShowDuration(false);
    }

  }, []);

  useEffect(() => {
    // Hide duration filter if type is webinar
    if(searchFilters.types && searchContext.types.id && config.hideWebinarDurationFilter ) {
      
      let currentType = null;
  
      if( searchContext.types ) {
        currentType = searchContext.types.slug;
      }

      if(currentType) {

        if( 
          currentType.includes("seminars") ||
          currentType.includes("onsite")
        ) {
          setShowDuration(true);
        } else {
          setShowDuration(false);
        }

      }

    }

    monthVisibility();

  }, [searchContext.types]);

  const handleCheckboxOnChange = (filter, event) => {
    let id = event.target.value;
    let checked = event.target.checked;
    let selected = new Set(searchContext[filter]);

    if (checked) {
      selected.add(id);
    } else {
      selected.delete(id);
    }

    let data = {
      ...searchContext,
    };
    
    data[filter] = Array.from(selected);
    data.current_page = 1;
    setSearchContext(data);
  };

  const handlePriceOnChange = (filter, event) => {
    clearTimeout(priceTimeout);

    priceTimeout = setTimeout(() => {
      let data = {
        ...searchContext,
      };

      data.price[filter] = event.target.value;
      data.current_page = 1;
      setSearchContext(data);
    }, 300);
  };

  const clearFilters = () => {
    let data = {
      ...searchContext,
    };

    data.price.min = null;
    data.price.max = null;
    data.durations = [];
    data.credits = [];
    data.formats = [];

    data.current_page = 1;
    setSearchContext(data);
  };

  const viewResults = () => {
    onClose();
  };

  const renderCheckboxes = (filter) => {
    let options = searchFilters[filter];

    return options.map((option) => {
      return (
        <Checkbox
          key={option.id}
          className="search__filter-checkbox"
          title={option.label}
          value={option.id}
          onChange={(event) => handleCheckboxOnChange(filter, event)}
          checked={searchContext[filter].includes(option.id)}
        />
      );
    });
  };

  return (
    <div
      className={
        "search__filters search__filters--" + (isOpen ? "open" : "closed")
      }
    >
      <div className="search__filters-pane">
        <div className="search__filters-pane-inner">
          <button
            className="search__button search__button--icon"
            onClick={handleClose}
          >
            Filtering My Results <ArrowLeftIcon />
          </button>

          { showFormatsFilter ? (
              <SearchFilter name="Format">
                {searchFilters.formats ? renderCheckboxes("formats") : null}
              </SearchFilter>
          ) : null }
          
          { showPriceFilter ? (
            <SearchFilter name="Price">
              <input
                type="number"
                placeholder="Minimum"
                className="search__filter-price"
                defaultValue={searchContext.price.min}
                onChange={(event) => handlePriceOnChange("min", event)}
              />
              <input
                type="number"
                placeholder="Maximum"
                className="search__filter-price"
                defaultValue={searchContext.price.max}
                onChange={(event) => handlePriceOnChange("max", event)}
              />
            </SearchFilter>
          ) : null }
          { showDuration ? (
            <>
              { config.hideDurationsFilter != true ? (
                <SearchFilter name="Duration">
                  {searchFilters.durations ? renderCheckboxes("durations") : null}
                </SearchFilter> ) : null }
            </>
          ) : null }
          { showMonths ? (
            <>
              { config.hideMonthsFilter != true ? (
                <SearchFilter name="Months">
                  {searchFilters.months ? renderCheckboxes("months") : null}
                </SearchFilter>
              ) : null }
            </>
          ) : null }
         
          { config.hideCreditsFilter != true ? (
            <SearchFilter name="Credits">
              {searchFilters.credits ? renderCheckboxes("credits") : null}
            </SearchFilter>
          ) : null }

          
          <button className="search__button" onClick={clearFilters}>
            Clear all filters
          </button>
        </div>

      </div>

      {isOpen ? (
        <>
          <button className="search__button search__button--view" onClick={viewResults}>
            View {resultContext.total_results} Results
          </button>
          <div className="search__filters-backdrop" onClick={handleClose}></div>
        </>
        ) : null}
    </div>
  );
};

export default SearchFilters;
