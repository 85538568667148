import React, { useContext } from "react";
import { SearchContext } from "../Search/Context/SearchContext";
import { SearchContextDefaultData } from "../Search/Context/SearchContextDefaultData";

const ClearAll = () => {
  const [searchContext, setSearchContext] = useContext(SearchContext);

  const handleOnClick = (id) => {
    let data = { ...SearchContextDefaultData, ...searchContext.reset };
    data.reset = searchContext.reset;
    data.query = "";

    setSearchContext(data);
  };

  return (
    <p className="search__clear-all" onClick={handleOnClick}>
      Clear All
    </p>
  );
};

export default ClearAll;
