const modalTriggers = document.querySelectorAll(
    '[data-behaviour="modal-trigger"]'
);

if (modalTriggers.length > 0) {
   
    modalTriggers.forEach((trigger) => {
        trigger.addEventListener('click', function (e) {
            
            const modalId = trigger.dataset.id;
            const modal = document.querySelector(`#${modalId}`);

            if(modal) {
                modal.classList.add('is-active');
                modal.setAttribute('aria-hidden', 'false');
            }

            e.preventDefault();
        });
    });

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }

        const modals = document.querySelectorAll('.modal.is-active');

        if (isEscape && modals.length > 0) {
            modals.forEach((modal) => {
                modal.classList.remove('is-active');
                modal.setAttribute('aria-hidden', 'true');
            });
        }
    };

}

const modalContainers = document.querySelectorAll(
    '.modal'
);

if (modalContainers.length > 0) {
   
    modalContainers.forEach((modal) => {
        modal.addEventListener('click', function (e) {
            
            if (
                e.target.classList.contains('modal__overlay') ||
                e.target.hasAttribute('data-behaviour', 'modal-close')
            ) {
                e.preventDefault()
                e.stopPropagation()
                modal.classList.remove('is-active');
                modal.setAttribute('aria-hidden', 'true');
            }
                    
        });
    });

}