import React, { useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import ActiveFilters from "./ActiveFilters";
import ActivePriceFilter from "./ActivePriceFilter";

const ActiveSearchFilters = ({ filters, config }) => {
    const [searchContext, setSearchContext] = useContext(SearchContext);

    const resetPriceFilter = () => {
        
        let data = {
            ...searchContext,
        };

        data.price.min = null;
        data.price.max = null;
        setSearchContext(data);
    }

    return (

        <>
            { config.hideActiveFilters != true ? (

                <div className="search__active-filters">

                    { searchContext.credits && searchContext.credits.length > 0 ? (
                        <ActiveFilters filters={filters.credits} active={searchContext.credits} type="credits" />
                    ): null }
        
                    { searchContext.months && searchContext.months.length > 0 ? (
                        <ActiveFilters filters={filters.months} active={searchContext.months} type="months"/>
                    ): null }
        
                    { searchContext.durations && searchContext.durations.length > 0 ? (
                        <ActiveFilters filters={filters.durations} active={searchContext.durations} type="durations"/> 
                    ): null }
        
                    { config.showFormatsToggle === false && searchContext.formats && searchContext.formats.length > 0 ? (
                        <ActiveFilters filters={filters.formats} active={searchContext.formats} type="formats"/> 
                    ): null }
        
                    { searchContext.price.min || searchContext.price.max  ? (
                        <div className="search-pill">
                            <ActivePriceFilter label="Price" min={searchContext.price.min} max={searchContext.price.max}/>
                            <span className="search-pill__close" onClick={()=>resetPriceFilter()}>&times;</span>
                        </div>
                    ): null }
        
                </div>

            ) : null }
        </> 

    );
};

export default ActiveSearchFilters;