import React, { useState, useEffect, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import { ResultContext } from "./Context/ResultContext";
import { DistanceContext } from "./Context/DistanceContext";
import { SearchContextDefaultData } from "./Context/SearchContextDefaultData";
import { ResultContextDefaultData } from "./Context/ResultContextDefaultData";
import { DistanceContextDefaultData } from "./Context/DistanceContextDefaultData";
import SearchContainer from "./SearchContainer";

export const SearchProvider = ({ initialState, children }) => {
  const searchState = useState({
    ...SearchContextDefaultData,
    ...initialState,
    reset: initialState,
  });

  return (
    <SearchContext.Provider value={searchState}>
      {children}
    </SearchContext.Provider>
  );
};

export const ResultProvider = ({ children }) => {
  const resultState = useState({
    ...ResultContextDefaultData,
  });

  return (
    <ResultContext.Provider value={resultState}>
      {children}
    </ResultContext.Provider>
  );
};

export const DistanceProvider = ({ children }) => {
  const distanceState = useState({
    ...DistanceContextDefaultData,
  });

  return (
    <DistanceContext.Provider value={distanceState}>
      {children}
    </DistanceContext.Provider>
  );
};

const Search = ({ initialState, config, cta, types }) => {
  return (
    <div className="search">
      <SearchProvider initialState={initialState}>
        <ResultProvider>
          <DistanceProvider>
            <SearchContainer config={config} cta={cta} types={types} />
          </DistanceProvider>
        </ResultProvider>
      </SearchProvider>
    </div>
  );
};
export default Search;
