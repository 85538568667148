import React from "react";

const MinusIcon = () => {
  return (
    <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.25 8.149a6.25 6.25 0 1 1-12.5.009 6.25 6.25 0 0 1 12.5-.01Z"
        stroke="#315DA6"
        strokeWidth={1.5}
      />
      <path
        d="M5.994 8.148h6"
        stroke="#315DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MinusIcon;
