import React from "react";

const SearchNoResults = (props) => {
  return (
    <div className="search__no-results">
      <p className="search__no-results-title">
        No Results Found
      </p>
      <p className="search__no-results-subtitle">
        Try performing a new search, selecting a different category, or applying
        fewer filters
      </p>
    </div>
  );
};

export default SearchNoResults;
