import React from "react";

const ChevronLeftIcon = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "scaleX(-1)",
      }}
    >
      <path
        d="M1.48828 0.5L5.98828 5L1.48828 9.5L0.433594 8.44531L3.87891 5L0.433594 1.55469L1.48828 0.5Z"
        fill="#315da6"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
