import React from 'react';
import ReactRender from '../tools/react-render';
import Search from './Search';
import Basket from './Basket';
import DynamicPrice from './DynamicPrice';
import AddToCart from './AddToCart';

const productSearchTarget = document.querySelector('[data-behaviour="react-product-search"]');

if (productSearchTarget) {
    let initialState = JSON.parse(
        productSearchTarget.getAttribute('data-initial-state') ?? '{}'
    );
    let config = JSON.parse(productSearchTarget.getAttribute('data-config') ?? '{}');
    let cta = JSON.parse(productSearchTarget.getAttribute('data-cta') ?? '{}');
    let types = JSON.parse(productSearchTarget.getAttribute('data-types') ?? '{}');

    ReactRender(
        <Search initialState={initialState} config={config} cta={cta} types={types} />,
        productSearchTarget
    );
}

const searchTarget = document.querySelector('[data-behaviour="react-search"]');

if (searchTarget) {
    let initialState = JSON.parse(
        searchTarget.getAttribute('data-initial-state') ?? '{}'
    );
    let config = JSON.parse(searchTarget.getAttribute('data-config') ?? '{}');
    let cta = JSON.parse(searchTarget.getAttribute('data-cta') ?? '{}');
    let types = JSON.parse(searchTarget.getAttribute('data-types') ?? '{}');

    ReactRender(
        <Search initialState={initialState} config={config} cta={cta} types={types} />,
        searchTarget
    );
}

const basketTargets = document.querySelectorAll('[data-behaviour="react-basket"]');

if (basketTargets.length > 0) {

    basketTargets.forEach((basketTarget) => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let config = JSON.parse(basketTarget.getAttribute('data-config') ?? '{}');
        let ipconfig = JSON.parse(basketTarget.getAttribute('data-ipconfig') ?? '{}');
        let inperson = JSON.parse(basketTarget.getAttribute('data-inperson') ?? false);

        const isInPerson = urlParams.get('inperson');

        if(isInPerson) {
            inperson = true;
        }

        ReactRender(<Basket config={config} ipconfig={ipconfig} inperson={inperson} />, basketTarget);
    });
    
}

const dynamicPriceTarget = document.querySelectorAll('[data-behaviour="react-dynamic-price"]');

if(dynamicPriceTarget.length > 0) {

    dynamicPriceTarget.forEach((price) => {
        let config = JSON.parse(price.getAttribute('data-config') ?? '{}');
        ReactRender(<DynamicPrice config={config} />, price);
    });

}

const addToCartTarget = document.querySelectorAll('[data-behaviour="react-add-to-cart"]');

if(addToCartTarget.length > 0) {

    addToCartTarget.forEach((addToCartButton) => {
        
        let type = addToCartButton.getAttribute('data-type');
        let id = addToCartButton.getAttribute('data-id');
        let quantity = addToCartButton.getAttribute('data-quantity');
        let label = addToCartButton.getAttribute('data-label');
        let addedLabel = addToCartButton.getAttribute('data-added-label');
        let classes = addToCartButton.getAttribute('data-classes');
        let arrow = addToCartButton.getAttribute('data-arrow');

        ReactRender(<AddToCart type={type} id={id} quantity={quantity} label={label} addedLabel={addedLabel} classes={classes} arrow={arrow} />, addToCartButton);

    });

}