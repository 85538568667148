import React, { useEffect, useState } from 'react';
import { addProductToBasket, getVariationsDynamicPrice } from '../../../../Services/BasketAPI';
import RadioOption from '../../../Components/RadioOption';
import BasketButton from '../../../Components/BasketButton';
import Price from '../../../Components/Price';
import AddToCartModal from '../../../../AddToCart/AddToCartModal';

const IndividualAccessTab = ({ config }) => {
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [dynamicPrices, setDynamicPrices] = useState(null);
    const [countryPrices, setCountryPrices] = useState(null);
    const [dynamicPrice, setDynamicPrice] = useState(null);
    const [countryPrice, setCountryPrice] = useState(null);
    const [addingToCart, setAddingToCart] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [sameDayModal, setSameDayModal] = useState(false);

    const addToCartEvent = new CustomEvent('addToCartEvent');
    
    let price = null;

    let currentVariation = config.product_variations.find(
        (variation) => variation.id === selectedVariation
    );
    
    if (currentVariation) {
        price = currentVariation.price;
    }

    const getDynamicPricing = async () => {
        setDynamicPrices( await getVariationsDynamicPrice(config.product_variations, 'product') );
        setCountryPrices( await getVariationsDynamicPrice(config.product_variations, 'product', true));
    }

    const changeVisibility = ( status ) => {
        setModalVisible(status);
    }

    useEffect(() => {
        getDynamicPricing();
    }, []);

    useEffect(() => {

        if(dynamicPrices) {
            const hasDynamicPrice = dynamicPrices.find(
                (variation) => variation.ProductId.replace(/\s/g, '') === selectedVariation
            );
            if( hasDynamicPrice ) {
                setDynamicPrice(hasDynamicPrice);
            }
        }

        if(countryPrices) {
            const hasCountryPrice = countryPrices.find(
                (variation) => variation.ProductId.replace(/\s/g, '') === selectedVariation
            );
            if( hasCountryPrice ) {
                setCountryPrice(hasCountryPrice);
            }
        }
        
    }, [selectedVariation]);

    return (
        <>
            <div className="r-basket__tabs-content form">
                <h4 className="r-basket__heading">
                    How would you like to take this course?
                </h4>
                <div>
                    {config.product_variations.map((variation) => {

                        if( variation.date && new Date(variation.date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0) ){
                            return null;
                        }

                        return (
                            <RadioOption
                                key={variation.id}
                                option={variation}
                                setSelected={setSelectedVariation}
                                isSelected={selectedVariation === variation.id}
                            />
                        );
                    })}
                </div>
                <div>
                    {price ? <Price price={price} dynamicPrice={dynamicPrice} countryPrice={countryPrice} perPerson={false} /> : null}
                </div>
            </div>
            <BasketButton
                label="Add to Cart"
                disabled={selectedVariation === null}
                adding={addingToCart}
                onClick={() => {
                    
                    if(currentVariation.date && new Date(currentVariation.date).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0) ) {
                        setSameDayModal(true);
                    } else {

                        if(!addingToCart) {
                            setAddingToCart(true);
                            addProductToBasket(selectedVariation, 'item', 'webinar').then(() => {
                                setModalVisible(true);
                                setAddingToCart(false);
                                document.dispatchEvent(addToCartEvent);
                            });
                        }

                    }

                }}
            />

            { modalVisible ? (
                <AddToCartModal visible={modalVisible} changeVisibility={changeVisibility}/>
            ) : null }

            { sameDayModal ? (
                <div className="cart-modal">
                    <div className="cart-modal__content">
                        { config.same_day_modal && config.same_day_modal.same_day_modal_title ? <p className="cart-modal__title">{config.same_day_modal.same_day_modal_title}</p> : null }
                        { config.same_day_modal && config.same_day_modal.same_day_modal_content ? <div className="cart-modal__body" dangerouslySetInnerHTML={{ __html: config.same_day_modal.same_day_modal_content }}/> : null }
                        <span className="cart-modal__close" onClick={() => { setSameDayModal(false) } }>×</span>
                    </div>
                </div>
            ) : null }

        </>
    );
};

export default IndividualAccessTab;
