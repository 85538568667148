import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { getUserLocation } from '../../../../Services/LocationAPI';
import { addEventToBasket, getDynamicPrice } from '../../../../Services/BasketAPI';
import { useDebounce } from '../../../../Hooks/useDebounce';
import { DistanceContext } from "../../../../Distance/Context/DistanceContext";
import { DistanceContextDefaultData } from "../../../../Distance/Context/DistanceContextDefaultData";
import BasketButton from '../../../Components/BasketButton';
import RadioOption from '../../../Components/RadioOption';
import PryorPlusPopup from '../../../Components/PryorPlusPopup';
import Price from '../../../Components/Price';
import Spinner from '../../../../Icons/Spinner';
import AddToCartModal from '../../../../AddToCart/AddToCartModal';


const IndividualAccessTab = ({ config, inPerson }) => {

    const [distanceContext, setDistanceContext] = useContext(DistanceContext);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedNumberOfParticipants, setSelectedNumberOfParticipants] =
        useState(1);
    const [pryorPlusPopupIsOpen, setPryorPlusPopupIsOpen] = useState(false);
    const [dynamicPrice, setDynamicPrice] = useState(config.price.dynamicPrice);
    const [countryPrice, setCountryPrice] = useState(config.price.countryPrice);
    const [showAdditionalDates, setShowAdditionalDates] = useState(false);
    const [postalCode, setPostalCode] = useState('');  
    const [addingToCart, setAddingToCart] = useState(false);
    const [loadingPrice, setLoadingPrice] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);


    const addToCartEvent = new CustomEvent('addToCartEvent');

    let dateOptions = config.date_options;
    let participantOptions = config.participant_options;
    let price = null;
    let pryorPlusUpsell = config.pryor_plus.enabled;

    if (selectedNumberOfParticipants >= config.price.minimum_group_count) {
        price = config.price.group_price;
    } else {
        price = config.price.price;
    }

    const getDynamicPricing = async () => {
        setDynamicPrice( await getDynamicPrice(config.product.code, config.type) );
        setCountryPrice( await getDynamicPrice(config.product.code, config.type, true));
        setLoadingPrice(false);
    }

    const changeVisibility = ( status ) => {
        setModalVisible(status);
    }

    const debouncedRequest = useDebounce(() => {

        let data = { ...DistanceContextDefaultData };
        getUserLocation(
            postalCode,
        ).then((response) => {

            if(response && response['success'] && response['data']) {
                const location = [{ lat: response['data'][1], lng: response['data'][2] }];
                data.from = location;
                data.error = false;
                setDistanceContext(data);
            }  else {
                data.error = true;
                setDistanceContext(data);
            }
            
        });

        
    });

    const postalCodeChange = (event) => {
        const value = event.target.value;
        setPostalCode(value);
        debouncedRequest();
    };

    useEffect(() => {

        let data = { ...DistanceContextDefaultData };

        const userLocation = localStorage.getItem('userLocation');
        if( userLocation ) {
            const locationParts = userLocation.split(',');
            if(locationParts[0] && locationParts[1] && locationParts[2]) {
                setPostalCode(locationParts[0]);
                const location = [{ lat: locationParts[1], lng: locationParts[2] }];
                data.from = location;
                setDistanceContext(data);
            }
        } else {
            getUserLocation().then((response) => {
                if(response[0]) {
                    setPostalCode(response[0]);
                }
            });
        }

        getDynamicPricing();

    }, []);

    useEffect(() => {
        setLoadingPrice(true);
        getDynamicPricing();
    }, [config]);

    return (
        <>
            <div className="r-basket__tabs-content form">
                <div>

                    {inPerson ? (
                        <div className="r-basket__location">
                            <h4 className="r-basket__heading r-basket__heading--sub">Choose your in-person date - 9am to 4pm</h4>
                            <div className={`r-basket__postcode${distanceContext.error ? ' r-basket__postcode--error' : ''}`}>
                                <label className="r-basket__postcode-label">Postal Code</label>
                                <input className="r-basket__postcode-input" value={postalCode} onChange={postalCodeChange}/>
                            </div>
                        </div>
                    ) : (
                        <h4 className="r-basket__heading r-basket__heading--sub">Choose your live virtual date</h4>
                    ) }

                    <fieldset className={`r-basket__options${showAdditionalDates ? ' r-basket__options--expanded' : ''}`}>

                        {dateOptions.slice(0, 3).map((dateOption) => {
                            return (
                                <RadioOption
                                    key={dateOption.id}
                                    option={dateOption}
                                    setSelected={setSelectedEvent}
                                    isSelected={selectedEvent === dateOption.id}
                                    inPerson={inPerson ? true : false}
                                />
                            );
                        })}

                        {dateOptions.length > 3 ? (
                            <>
                                { !showAdditionalDates ? (
                                    <p className="r-basket__date-toggle" onClick={()=>setShowAdditionalDates(true)}>Show {dateOptions.length - 3} additional dates</p>
                                ) : null }
                            </>
                        ) : null }

                        { showAdditionalDates ? (
                            <>
                                {dateOptions.slice(3, dateOptions.length).map((dateOption) => {
                                    return (
                                        <RadioOption
                                            key={dateOption.id}
                                            option={dateOption}
                                            setSelected={setSelectedEvent}
                                            isSelected={selectedEvent === dateOption.id}
                                            inPerson={inPerson ? true : false}
                                        />
                                    );
                                })}
                            </>
                        ) : null }
                            
                    </fieldset>

                    { showAdditionalDates ? (
                        <p className="r-basket__date-toggle" onClick={()=>setShowAdditionalDates(false)}> See fewer dates</p>
                    ) : null }

                    {/* <p>
                        or choose from{' '}
                        <span className="u-text--blue u-bold">
                            {dateOptions.length}
                        </span>{' '}
                        scheduled events in the next 6 months
                    </p>
                    <div>
                        <Select
                            options={dateOptions}
                            setValue={setSelectedEvent}
                        />
                    </div> */}
                </div>
                {/* <div>
                    <h4 className="u-text--blue">
                        Select number of participants
                    </h4>
                    <p>
                        You can buy as many individual passes as you like. if
                        you require personalised team training please select{' '}
                        <span className="u-text--blue u-bold">
                            “Buy for my team”
                        </span>
                    </p>
                    <Select
                        options={participantOptions}
                        defaultValue={1}
                        setValue={setSelectedNumberOfParticipants}
                    />
                </div> */}
                { loadingPrice ? <div className="r-basket__price"><Spinner/></div> : <>
                    {price ? <Price price={price} dynamicPrice={dynamicPrice} countryPrice={countryPrice} selectedNumberOfParticipants={selectedNumberOfParticipants} groupPrice={config.price.group_price} /> : null}
                </> }
                
                {config.price.price && config.price.group_price && config.price.minimum_group_count ? (
                    <div className="r-basket__savings">Save ${config.price.price - config.price.group_price} per person for {config.price.minimum_group_count} or more attendees</div>
                ) : null }
                {pryorPlusPopupIsOpen ? (
                    <PryorPlusPopup config={config.pryor_plus} price={price} />
                ) : null}
            </div>
            <BasketButton
                label="Register"
                disabled={
                    selectedEvent === null ||
                    selectedNumberOfParticipants === null
                }
                adding={addingToCart}
                onClick={() => {

                    if(!addingToCart) {
                        setAddingToCart(true);
                        addEventToBasket(
                            selectedEvent,
                            selectedNumberOfParticipants
                        ).then(() => {
                            if(pryorPlusUpsell) {
                                setPryorPlusPopupIsOpen(true);
                                setAddingToCart(false);
                                document.dispatchEvent(addToCartEvent);
                            } else {
                                setModalVisible(true);
                                setAddingToCart(false);
                                document.dispatchEvent(addToCartEvent);
                            }
                        });
                        
                    }

                }}
            />

            { modalVisible ? (
                <AddToCartModal visible={modalVisible} changeVisibility={changeVisibility}/>
            ) : null }

        </>
    );
};

export default IndividualAccessTab;
