import { debounce } from './utilities';

const tabletBP = '834';
const footer = document.querySelector('[data-ref="site-footer"]');
const footer_accordions = document.querySelectorAll(
    '[data-behaviour="footer-accordion"]'
);

const accordion_open_class = 'footer-menu__item--open';

footer_accordions.forEach((accordion) => {
    accordion.addEventListener('click', (e) => {
        e.preventDefault();
        let parent = accordion.closest('[data-ref="menu-item"]');
        parent.classList.toggle(accordion_open_class);
    });
});

//Close accordions on resize
window.addEventListener(
    'resize',
    debounce(() => {
        if (document.body.clientWidth < tabletBP) {
            let close_accordions = footer.querySelectorAll(
                '.' + accordion_open_class
            );

            if (close_accordions) {
                close_accordions.forEach((accordion) => {
                    accordion.classList.remove(accordion_open_class);
                });
            }
        }
    }, 300)
);
