import React, { useState, useContext, useEffect } from "react";
import ReturnIcon from "../Icons/ReturnIcon";
import { SearchContext } from "./Context/SearchContext";

const SearchBar = () => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [query, set_query] = useState(searchContext.query);

  useEffect(() => {
    set_query(searchContext.query);
  }, [searchContext.query]);

  const handleOnChange = (event) => set_query(event.target.value);

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      triggerSearch();
    }
  };

  const triggerSearch = () => {
    let new_data = {
      ...searchContext,
      query: query,
    };

    setSearchContext(new_data);
  };

  return (
    <div className="search__bar">
      <input
        type="text"
        name="search"
        id="search"
        className="search__bar-input"
        value={query}
        placeholder="Search..."
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
      <button
        type="submit"
        className="search__bar-button"
        onClick={triggerSearch}
      >
        <ReturnIcon className="search__bar-icon" /> Enter
      </button>
    </div>
  );
};

export default SearchBar;
