import Glide from '@glidejs/glide';
const glides = document.querySelectorAll('[data-behaviour="glide"]');

if (glides.length > 0) {
    glides.forEach((glide) => {
        new Glide(glide, {
            type: glide.dataset.glideType,
            focusAt: glide.dataset.glideFocusat,
            perView: glide.dataset.glidePerview,
            animationDuration: '900',
            animationTimingFunc: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
            breakpoints: {
                600: {
                    perView: glide.dataset.glidePerviewMobile
                        ? glide.dataset.glidePerviewMobile
                        : null,
                },
                940: {
                    perView: glide.dataset.glidePerviewTablet
                        ? glide.dataset.glidePerviewTablet
                        : null,
                },
                1040: {
                    perView: glide.dataset.glidePerview,
                },
            },
        }).mount();
    });
}
