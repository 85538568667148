import { setCookie } from "../../modules/utilities";

const call_api = async (action, data = {}) => {
	const form_data = new FormData();
	form_data.append('data', JSON.stringify(data));
	
	return fetch(`${wp_ajax.ajax_url}?action=${action}`, {
		method: 'POST',
		body: form_data
	})
	.then(response => response.json())
	.catch(error => {
		console.log('Error during fetch: ' + error);
	});
}

export const addEventToBasket = async (eventCode, quantity) => {
    return await call_api('add_event_to_basket', {
        eventCode: eventCode,
        quantity: quantity
    })
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
            console.log(response.data);
            setCookie('CartID', response.data.cartID, 8766);
			return response.data;
		}
	});
}

export const addProductToBasket = async (itemNumber, type, location) => {
    return await call_api('add_product_to_basket', {
        itemNumber: itemNumber,
		type: type,
		referrerUrl: location
    })
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
            console.log(response.data);
            setCookie('CartID', response.data.cartID, 8766);
			return response.data;
		}
	});
}

export const addPryorPlusToBasket = async () => {
    return await call_api('add_pryor_plus_to_basket')
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});
}

export const submitBasketCallback = async (data) => {
    return await call_api('submit_basket_callback', data)
    .then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
            console.log(response.data);
			return response.data;
		}
	});
}

export const getDynamicPrice = async (itemNumber, type, countryOnly = false, forceDefault = false) => {
    return await call_api('get_dynamic_price', {
        itemNumber: itemNumber,
		type: type,
		countryOnly: countryOnly,
		forceDefault: forceDefault
    })
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});
}

export const getVariationsDynamicPrice = async (items, type, countryOnly) => {

	return await call_api('get_variation_dynamic_price', {
        items: items,
		type: type,
		countryOnly: countryOnly
    })
	.then(response => {
		if (!response.success) {
			console.log(response.message);
		} else {
			return response.data;
		}
	});

}