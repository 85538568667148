const jumplinks = document.querySelectorAll('[data-behaviour="jumplink"]');

if( jumplinks.length > 0 ) {

    jumplinks.forEach((jumplink) => {

        const href = jumplink.getAttribute("href");

        if(href && href.length > 1) {
            
            const destination = document.querySelector(href);    

            if(destination) {
                jumplink.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let offset = destination.offsetTop - 70;

                    window.scrollTo({
                        behavior: "smooth",
                        top: offset
                    });
                });
            }

        }
        
    });

}

const relatedSeminarLinks = document.querySelectorAll('.greybox a.button');

if( relatedSeminarLinks.length > 0 ) {

    relatedSeminarLinks.forEach((jumplink) => {

        const href = jumplink.getAttribute("href");

        if(href && href.length > 1) {
            
            const destination = document.querySelector(href);    

            if(destination) {
                jumplink.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let offset = destination.offsetTop - 70;

                    window.scrollTo({
                        behavior: "smooth",
                        top: offset
                    });
                });
            }

        }
        
    });

}