import React, { useState } from "react";
import { capitalize_first_letter } from "../Utilities/StrHelper";

const Checkbox = ({
  className = "",
  title,
  value,
  onChange,
  checked = false,
}) => {
  return (
    <div className={"checkbox " + className}>
      <input
        type="checkbox"
        name={title.toLowerCase()}
        value={value}
        checked={checked}
        id={"checkbox_" + title.toLowerCase()}
        onChange={(event) => {
          onChange(event);
        }}
      />
      <label htmlFor={"checkbox_" + title.toLowerCase()}>
        {capitalize_first_letter(title)}
      </label>
    </div>
  );
};
export default Checkbox;
