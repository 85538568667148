import { debounce, isOverflown } from "./utilities";
const tabs = document.querySelectorAll('[data-behaviour="tabs"]');
const tabsList = document.querySelectorAll('[data-behaviour="tabs-list"]');

if (tabs.length > 0) {
    tabs.forEach((tab) => {
        const tabsBtns = tab.querySelectorAll('[data-behaviour="tabs-button"]');

        const tabsContents = tab.querySelectorAll(
            '[data-behaviour="tabs-content"]'
        );

        function displayCurrentTab(current, first = false) {
            for (let i = 0; i < tabsContents.length; i++) {
                current === i
                    ? tabsContents[i].classList.add('active')
                    : tabsContents[i].classList.remove('active');
            }
        }

        displayCurrentTab(0, true);

        tabsBtns.forEach((button) => {
            button.addEventListener('click', (event) => {
                for (let i = 0; i < tabsBtns.length; i++) {
                    if (event.target === tabsBtns[i]) {
                        let activeTabs = tab.querySelectorAll('.active');
                        for (let i = 0; i < activeTabs.length; i++) {
                            activeTabs[i].classList.remove('active');
                        }

                        displayCurrentTab(i);
                        break;
                    }
                }

                tabsBtns.forEach((el) => el.classList.remove('active'));

                button.classList.add('active');
            });
        });

        const tabList = tab.querySelector('.tabs__list');

        if( isOverflown(tabList )) {
            tab.classList.add('tabs--overflow');
        }

    });

    tabsList.forEach((el) => {
        let tabItems = el.querySelector('.active');
    });

    // Add overflow styles to tabs on resize
    window.addEventListener(
        'resize',
        debounce(() => {

            if (tabs.length > 0) {
                
                tabs.forEach((tab) => {

                    const tabList = tab.querySelector('.tabs__list');

                    if( isOverflown(tabList )) {
                        tab.classList.add('tabs--overflow');
                    } else {
                        tab.classList.remove('tabs--overflow');
                    }
                    
                });
            }

        }, 300)

    );

}
